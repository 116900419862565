
// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: matt@sketchup.com (Matt Gates)

import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';

import EventBus from '../typescript/EventBus';
import { Token } from '../typescript/inject-tokens';
import { TrackingEventEnum } from '../typescript/tracking/tracking-event.enum';
import { TrackingService } from '../typescript/tracking/tracking.service';

@Component({})
export default class ZoomControlsComponent extends Vue {
  @Prop({ required: true }) zoomLevelMax: number;
  @Prop({ required: true }) zoomLevelMin: number;
  @Prop({ required: true }) zoomLevel: number;

  @Inject(Token.TrackingService) trackingService!: TrackingService;

  trackZoomIn = true;
  trackZoomOut = true;

  zoomIn(): void {
    if (this.allowZoomIn) {
      if (this.trackZoomIn) {
        this.trackingService.send(TrackingEventEnum.ClickedZoomIn);
      }
      EventBus.$emit('update:zoom', this.zoomLevel + 1);
      this.trackZoomIn = false;
    }
  }

  zoomOut(): void {
    if (this.allowZoomOut) {
      if (this.trackZoomOut) {
        this.trackingService.send(TrackingEventEnum.ClickedZoomOut);
      }
      EventBus.$emit('update:zoom', this.zoomLevel - 1);
      this.trackZoomOut = false;
    }
  }

  get allowZoomIn(): boolean {
    return this.zoomLevel < this.zoomLevelMax;
  }

  get allowZoomOut(): boolean {
    return this.zoomLevel > this.zoomLevelMin;
  }
}
