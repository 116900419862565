import { MapServices } from '../interfaces/MapInterfaces';
import { clientVersionAllowsFeature } from '../Utils';
import { AddLocationClients } from '../customer_data_platform/AnalyticsHandler';

export enum Platform {
  Desktop = 'Desktop',
  iPad = 'iPad',
  P11 = 'P11',
  Schools = 'Schools',
}

export function getPlatform(services: MapServices, referrer: string): Platform {
  if (referrer !== null && referrer !== '') {
    // Coming from the desktop client.
    const url = new URL(referrer);
    const isSchools = url.hostname.includes('edu.sketchup.com') || url.searchParams.get('simulate_edu') === 'true';
    if (isSchools) {
      return Platform.Schools;
    }
  }

  if (services.ipad) {
    return Platform.iPad;
  } else if (clientVersionAllowsFeature(0, services.client_version)) {
    return Platform.Desktop;
  } else {
    return Platform.P11;
  }
}

export function getApplicationNameForPlatform(platform: Platform): AddLocationClients {
  switch (platform) {
    case Platform.Desktop:
      return 'su-desktop';
    case Platform.iPad:
      return 'su-mobile';
    case Platform.P11:
    case Platform.Schools:
      return 'su-web';
  }
}
