import { EosNotification } from '../dto/eos-notification.interface';

export class EosNotificationService {
  static async getEosNotification(): Promise<EosNotification | undefined> {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open('GET', '/addlocation/v1.0/display-eos-notification', true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const responseJSON: EosNotification = JSON.parse(xhr.response);
          resolve(responseJSON);
        } else if (xhr.status === 204) {
          resolve(undefined);
        } else {
          reject(xhr.status);
        }
      };
      xhr.onerror = error => {
        reject(error);
      };
      xhr.send();
    });
  }
}
