import * as Leaflet from 'leaflet';

import { getTileFromPoint, getTileBoundsInDegrees } from '../TileGrid';

export class TileGridLayer {
  private enabled: boolean = false;
  private currentLayer: Array<Leaflet.SVGOverlay>;

  private readonly layerGroup: Leaflet.LayerGroup;

  constructor(private readonly map: Leaflet.Map) {
    this.layerGroup = new Leaflet.LayerGroup<any>();
  }

  update(enabled: boolean, zoomLevel?: number, gridTileSize?: number): void {
    const wasEnabled = this.enabled;
    this.enabled = enabled;
    if (this.enabled) {
      if (this.currentLayer) {
        this.currentLayer.forEach(this.layerGroup.removeLayer.bind(this.layerGroup));
      }

      this.currentLayer = this.createGridLayer(zoomLevel, gridTileSize);
      this.currentLayer.forEach(this.layerGroup.addLayer.bind(this.layerGroup));

      if (!wasEnabled) {
        this.map.addLayer(this.layerGroup);
      }
    } else if (this.currentLayer) {
      this.layerGroup.clearLayers();
      this.map.removeLayer(this.layerGroup);
      this.currentLayer = undefined;
    }
  }

  get isEnabled(): boolean {
    return this.enabled;
  }

  createGridLayer(zoomLevel: number, gridTileSize: number): Array<Leaflet.SVGOverlay> {
    const mapCenter = this.map.getCenter();
    const overlayRegionTileZoom = 10;

    const centerTile = getTileFromPoint(
      {
        lat: mapCenter.lat,
        lng: mapCenter.lng,
      },
      overlayRegionTileZoom,
    );

    const createSvgElement = () => {
      const gridToZoomRatio = Math.pow(2, zoomLevel - overlayRegionTileZoom);
      const gridToSvgRatio = Math.pow(2, gridTileSize - overlayRegionTileZoom);
      const viewPort = 1024;
      const width = viewPort / gridToSvgRatio;
      const height = viewPort / gridToSvgRatio;
      const strokeRatio = Math.sqrt(viewPort / gridToZoomRatio) / Math.sqrt(viewPort / gridToSvgRatio);

      const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svgElement.setAttribute('viewBox', `0 0 ${viewPort} ${viewPort}`);
      svgElement.innerHTML = `
                <defs>
                    <pattern width="${width}" height="${height}" patternUnits="userSpaceOnUse" id="gridPattern" viewBox="0 0 16 16">
                        <g>
                            <rect x="0" y="0" width="16" height="16" style="stroke-width: ${
                              0.125 * strokeRatio
                            }; stroke: #fff; fill: none;"></rect>
                        </g>
                    </pattern>
                </defs>
                <rect x="${-viewPort}" y="${-viewPort}" width="${viewPort * 3}" height="${
                  viewPort * 3
                }" fill="url('#gridPattern')"></rect>`;
      return svgElement;
    };

    const overlays = [];

    [-1, 0, 1].forEach(latitudeIndex => {
      [-1, 0, 1].forEach(longitudeIndex => {
        const overlayTileBounds = getTileBoundsInDegrees(
          {
            x: centerTile.id.x + longitudeIndex,
            y: centerTile.id.y + latitudeIndex,
          },
          overlayRegionTileZoom,
        );

        const overlayBounds = Leaflet.latLngBounds(
          {
            lat: overlayTileBounds.south,
            lng: overlayTileBounds.west,
          },
          {
            lat: overlayTileBounds.north,
            lng: overlayTileBounds.east,
          },
        );
        overlays.push(Leaflet.svgOverlay(createSvgElement(), overlayBounds));
      });
    });

    return overlays;
  }
}
