import { OSName } from '../enums/os-name.enum';

export class BrowserDetailsModel {
  constructor(
    readonly name: string,
    readonly version: string | number,
    readonly osName: OSName | undefined,
    readonly osVersion: string | number,
    readonly deviceModel: string | undefined,
    readonly deviceManufacturer: string | undefined,
  ) {}
}
