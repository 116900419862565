// Copyright 2018 - 2019 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Handles getting user login information from the SketchUp Login Service.

import { getActiveEnvironment } from './Environment';
import { Stacks } from './enums/stacks.enum';

/**
 * Defines the response body of a request to the login service.
 */
export interface LoginResponse {
  /**
   * A user's trimble id.
   */
  trimbleid: string;
  /**
   * A string identifying the authentication id.
   */
  authid: string;
  /**
   * A string identifying the authentication source.
   */
  authsource: string;
  /**
   * User's email.
   */
  email: string;
  /**
   * First name of user.
   */
  firstname: string;
  /**
   * Last name of user.
   */
  lastname: string;
  /**
   * A user's profile image url.
   */
  image: string;
  /**
   * The SketchUp AuthKey (E.g., DSID, SSID, SID)
   */
  sketchupKey: string;
  /**
   * The SketchUp Login Source.
   */
  sketchupLoginSource: string | null;
}

// Login url for different stacks.
/**
 * Returns boolean promise based on whether user is logged in.
 */
export async function isLoggedIn(): Promise<boolean> {
  try {
    const loginInfo = await getLoginInfo();
    return loginInfo.trimbleid != null;
  } catch (_) {
    return false;
  }
}

/**
 * Return's a user's login information. If not logged in, returns null.
 */
export async function getLoginInfo(): Promise<LoginResponse> {
  try {
    const url = getLoginURLForStack(getActiveEnvironment());
    const login = await fetch(url, { method: 'GET', mode: 'cors', credentials: 'include' });
    if (login.ok) {
      return login.json();
    } else {
      throw new Error(`Error occurred while fetching login data from ${url}.`);
    }
  } catch (e) {
    // Something went wrong. User is not logged in or AuthKey has expired, or some other issue.
    return {
      trimbleid: null,
      authid: null,
      authsource: null,
      email: null,
      firstname: null,
      lastname: null,
      image: null,
      sketchupKey: null,
      sketchupLoginSource: null,
    };
  }
}

/**
 * Returns the appropriate SketchUp Login Service endpoint URL for the active environment.
 */
function getLoginURLForStack(stack: Stacks): string {
  switch (stack) {
    case Stacks.Dev:
      return 'https://stg-login.sketchup.com/api/v1.0/users/';
    case Stacks.Local:
    case Stacks.Sandbox:
    case Stacks.Stg:
      return 'https://stg-login.sketchup.com/api/v1.0/users/';
    default:
      return 'https://login.sketchup.com/api/v1.0/users/';
  }
}
