// Copyright 2018 - 2019 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Contains operations regarding provider map tiles and generating tile outlines(tile grid).
// Several formulas adapted from Skore:
// https://bitbucket.trimble.tools/projects/SU/repos/skore/  (URL cont'd on next line)
// browse/common/geoutils/sphericalmercator.cpp
import * as Leaflet from 'leaflet';

import * as Geo from './Geometry';

const MAX_TILE_EXTENT_METERS = computeTileExtentForZoomLevel(1);
const MAX_TILE_ZOOM_LEVEL = 24;

const TILE_RESOLUTION_METERS = getTileResolutionInMetersForAllZoomLevels();

/**
 * Represents a tile bounds object in degrees.
 */
export interface TileBoundsInDeg {
  north: number;
  south: number;
  west: number;
  east: number;
}

/**
 * Represent the object returned from areaSelect_.getBounds().
 */
export interface AreaSelectBounds {
  _southWest: {
    lat: number;
    lng: number;
  };
  _northEast: {
    lat: number;
    lng: number;
  };
}

/**
 * Represent a point in degrees on a grid line.
 */
interface GridPoint {
  lat: number;
  lng: number;
}

/**
 * Map tile identifier.
 */
interface TileId {
  x: number;
  y: number;
}

/**
 * Map tile bounds in meters.
 */
interface TileBounds {
  north: number;
  south: number;
  west: number;
  east: number;
}

/**
 * Represents a map tile.
 */
interface Tile {
  id: TileId;
  bounds: TileBounds;
}

/**
 * Group of tiles within an area-of-interest at a zoom level.
 */
interface TileSet {
  zoom: number;
  nw_tile: Tile; // extreme north-west tile.
  se_tile: Tile; // extreme south-east tile.
}

export function getTileQtyFromAreaSelectBounds(
  areaSelectBounds: AreaSelectBounds,
  zoom: number,
  importLevel?: number,
): number {
  return getTileQtyFromBounds(
    new Leaflet.LatLngBounds(areaSelectBounds._southWest, areaSelectBounds._northEast),
    zoom,
    importLevel,
  );
}

/**
 * Gets the quanitity of tiles that overlap with the map area that the user selects.
 *
 * @param areaSelectBounds A bounds object returned from this.areaSelect_.getBounds().
 * @param zoom Map zoom.
 * @param importLevel The import level the user has chosen from the import level slider.
 */
export function getTileQtyFromBounds(
  areaSelectBounds: Leaflet.LatLngBounds,
  zoom: number,
  importLevel?: number,
): number {
  const boundsObj: TileBoundsInDeg = {
    north: areaSelectBounds.getNorthEast().lat,
    south: areaSelectBounds.getSouthWest().lat,
    east: areaSelectBounds.getNorthEast().lng,
    west: areaSelectBounds.getSouthWest().lng,
  };
  // The zoom level from which the quantity of tiles will be calculated.
  const tileZoom = importLevel || zoom;
  const tileSet = findTileSet(tileZoom, boundsObj);
  // Total number of tiles is qty across longitude (x) * qty across latitude (y).
  return getTileQtyAcrossBoundsXAxis(tileSet) * getTileQtyAcrossBoundsYAxis(tileSet);
}

export function getTileFromPoint(point: GridPoint, zoom: number): Tile {
  const latInMeters = Geo.convertLatToMeters(point.lat);
  const lngInMeters = Geo.convertLngToMeters(point.lng);
  return findTile(zoom, latInMeters, lngInMeters);
}

export function getTileBoundsInDegrees(tile: TileId, zoom: number): TileBoundsInDeg {
  const tileBoundsInMeters = computeTileBounds(tile, zoom);
  return {
    north: Geo.convertMetersToLat(tileBoundsInMeters.north),
    south: Geo.convertMetersToLat(tileBoundsInMeters.south),
    west: Geo.convertMetersToLng(tileBoundsInMeters.west),
    east: Geo.convertMetersToLng(tileBoundsInMeters.east),
  };
}

/**
 * Gets the bounds (lat/lng) of the tile that a given point resides within.
 *
 * @param point An object with lat and lng keys (in degrees).
 * @param zoom The current zoom.
 */
export function getTileBoundsInDegreesFromPoint(point: GridPoint, zoom: number): TileBoundsInDeg {
  const tileBoundsInMeters = getTileFromPoint(point, zoom).bounds;
  return {
    north: Geo.convertMetersToLat(tileBoundsInMeters.north),
    south: Geo.convertMetersToLat(tileBoundsInMeters.south),
    west: Geo.convertMetersToLng(tileBoundsInMeters.west),
    east: Geo.convertMetersToLng(tileBoundsInMeters.east),
  };
}

/**
 * Returns the quantity of tiles found across the longitudinal(x-axis) span of a given selected
 * area.
 *
 * @param tileSet The tileSet for a given selected area.
 */
function getTileQtyAcrossBoundsXAxis(tileSet: TileSet): number {
  const westernmostLng = tileSet.nw_tile.bounds.west;
  const easternmostLng = tileSet.se_tile.bounds.east;
  // East longitude values are larger than west.
  const tileLength = tileSet.nw_tile.bounds.east - westernmostLng;
  const tileCount = (easternmostLng - westernmostLng) / tileLength;
  // Because these calculations go out ten or more decimal places, there is often a very small
  // difference from the value being a nice round integer.
  return Math.round(tileCount);
}

/**
 * Returns the quantity of tiles found across the latitudinal(y-axis) span of a given selected
 * area.
 *
 * @param tileSet The tileSet for a given selected area.
 */
function getTileQtyAcrossBoundsYAxis(tileSet: TileSet): number {
  const northernmostLat = tileSet.nw_tile.bounds.north;
  const southernmostLat = tileSet.se_tile.bounds.south;
  // North latitude values are larger than south.
  const tileWidth = northernmostLat - tileSet.nw_tile.bounds.south;
  const tileCount = (northernmostLat - southernmostLat) / tileWidth;
  // Because these calculations go out ten or more decimal places, there is often a very small
  // difference from the value being a nice round integer.
  return Math.round(tileCount);
}

/**
 * Returns an array containing the tile resolution in meters at each zoom level up to max zoom.
 * Zoom level is represented by the index (i). E.g., the tile resolution of zoom level three is
 * stored at TILE_RESOLUTION_METERS[3].
 */
function getTileResolutionInMetersForAllZoomLevels(): Array<number> {
  const tile_res_meters = [];
  for (let i = 1; i <= MAX_TILE_ZOOM_LEVEL; i++) {
    tile_res_meters[i] = computeTileExtentForZoomLevel(i);
  }
  return tile_res_meters;
}

/**
 * Calculates the tile size (width or height) in meters at a given zoom level.
 */
function computeTileExtentForZoomLevel(zoom: number): number {
  return Geo.EARTH_CIRCUMFERENCE_METERS / Math.pow(2, zoom);
}

/**
 * Computes the bounds of a given tile at a given zoom level.
 */
export function computeTileBounds(id: TileId, zoom: number): TileBounds {
  const north = MAX_TILE_EXTENT_METERS - id.y * TILE_RESOLUTION_METERS[zoom];
  const south = north - TILE_RESOLUTION_METERS[zoom];
  const west = -MAX_TILE_EXTENT_METERS + id.x * TILE_RESOLUTION_METERS[zoom];
  const east = west + TILE_RESOLUTION_METERS[zoom];
  const bounds: TileBounds = {
    north: north,
    south: south,
    west: west,
    east: east,
  };
  return bounds;
}

/**
 * Computes a unique id for a tile based on location and a given zoom.
 */
function computeTileId(zoom: number, horizontalMeters: number, verticalMeters: number): TileId {
  const rawLatitude = (MAX_TILE_EXTENT_METERS - verticalMeters) / TILE_RESOLUTION_METERS[zoom];
  const rawLongitude = (MAX_TILE_EXTENT_METERS + horizontalMeters) / TILE_RESOLUTION_METERS[zoom];
  const tile: TileId = {
    x: Math.floor(rawLongitude),
    y: Math.floor(rawLatitude),
  };
  return tile;
}

/**
 * Returns a map tile object based on location and a given zoom.
 */
export function findTile(zoom: number, latMeters: number, lngMeters: number): Tile {
  const tileId = computeTileId(zoom, lngMeters, latMeters);
  const tile: Tile = {
    id: tileId,
    bounds: computeTileBounds(tileId, zoom),
  };
  return tile;
}

/**
 * Finds a tile set based on the entire add location view at a given zoom level.
 */
function findTileSet(zoom: number, bounds: TileBoundsInDeg): TileSet {
  const latMin = Geo.convertLatToMeters(bounds.south);
  const latMax = Geo.convertLatToMeters(bounds.north);
  const lngMin = Geo.convertLngToMeters(bounds.west);
  const lngMax = Geo.convertLngToMeters(bounds.east);

  return {
    zoom: zoom,
    nw_tile: findTile(zoom, latMax, lngMin),
    se_tile: findTile(zoom, latMin, lngMax),
  };
}
