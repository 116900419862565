// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: Cole Carroll (cole@sketchup.com)
// Contains interfaces describing Add Location analytics events.

/**
 * This describes which event schema we are using. This helps CDP team track which version of
 * event properties we are sending
 */
export const EVENT_SCHEMA_VERSION = 'v1';

/**
 * Describes which tile provider type we are using for the import.
 */
export enum TileProviderTypes {
  DigitalGlobe = 'digitalglobe',
  Bing = 'bing',
  MapOnly = 'map',
}

/**
 * Describes the name of the different CDP events we wish to capture in Amplitude.
 */
export enum AddLocationEventTypes {
  CancelImport = 'Canceled Add Location Import',
  ClosedApp = 'Closed Add Location',
  Import = 'Imported Add Location Imagery',
  LaunchedApp = 'Launched Add Location',
  PayloadError = 'Imagery Payload Error',
}

/**
 * The event properties sent to Amplitude when Add Location is launched.
 */
export interface LaunchedAddLocationProperties {
  /**
   * This describes which event schema we are using. This helps CDP team track which version of
   * event properties we are sending
   */
  event_schema_version: string;
  /**
   * The current version of the client running Add Location.
   */
  client_version?: number;
  /**
   * the sku of the latest activation available
   */
  sku: string;
}

/**
 * The event properties sent to Amplitude when Add Location is closed.
 */
export interface ClosedAddLocationProperties {
  /**
   * This describes which event schema we are using. This helps CDP team track which version of
   * event properties we are sending
   */
  event_schema_version: string;
}

/**
 * The event properties sent to Amplitude when an import is either executed or cancelled.
 */
export interface ImportProperties {
  /**
   * Describes the tile provider type.
   */
  import_type: TileProviderTypes;
  /**
   * The quantity of tiles being imported.
   */
  tile_quantity: number;
  /**
   * The current zoom level of the map view.
   */
  view_level: number;
  /**
   * The current tile zoom level for the import.
   */
  import_level: number;
  /**
   * The closest city to the centerpoint of the import selected area.
   */
  city: string;
  /**
   * The state in which the import selected area resides.
   */
  state: string;
  /**
   * The country in which the import selected area resides.
   */
  country: string;
  /**
   * The centerpoint latitude of the import selected area.
   */
  origin_lat: number;
  /**
   * The centerpoint longitude of the import selected area.
   */
  origin_lng: number;
  /**
   * This describes which event schema we are using. This helps CDP team track which version of
   * event properties we are sending
   */
  event_schema_version: string;
}

/**
 * Describes the dependencies we need to obtain from the Add Location Page in order to
 * derive our Import Properties.
 */
export interface ImportPropertiesDependencies {
  /**
   * Centerpoint of the map.
   */
  centerpoint: L.LatLng;
  /**
   * A string that gives the city (and possibly the state), reverse geocoded from the centerpoint.
   */
  cityState: string;
  /**
   * A string that gives the country, reverse geocoded from the centerpoint.
   */
  country: string;
  /**
   * Which Tile Provider is used for the import.
   */
  importType: TileProviderTypes;
  /**
   * Quantity of tiles selected for the import.
   */
  tileQuantity: number;
  /**
   * The current zoom level of the map view.
   */
  zoom: number;
  /**
   * The current tile zoom level for the import.
   */
  zoomForImport: number;
}
