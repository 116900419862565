import { EVENT_SCHEMA_VERSION } from '../customer_data_platform/AnalyticsInterfacesV1';
import { getApplicationNameForPlatform, Platform } from '../enums/platform.enum';
import { ServicesInterface } from '../interfaces/ServicesInterface';
import { BrowserDetailsModel } from '../models/browser-details-model';
import { extractClientVersion } from '../Utils';

export class LaunchEventData {
  readonly event_schema_version: string = EVENT_SCHEMA_VERSION;
  readonly client_version: number | string;
  readonly platform: string;
  readonly os_name: string;
  readonly os_version: string;
  readonly display: string;
  readonly browser_name: string;
  readonly browser_version: string;
  readonly device_model: string;
  readonly device_manufacturer: string;
  readonly sku_addlocation: string;

  constructor(services: ServicesInterface, browserDetails: BrowserDetailsModel, platform: Platform, sku: string) {
    this.platform = getApplicationNameForPlatform(platform);
    this.client_version = extractClientVersion(services.client_version || '');
    this.os_name = browserDetails.osName;
    this.os_version = String(browserDetails.osVersion);
    this.display = screen.width + 'x' + screen.height;
    this.browser_name = browserDetails.name;
    this.browser_version = String(browserDetails.version);
    this.device_model = browserDetails.deviceModel;
    this.device_manufacturer = browserDetails.deviceManufacturer;
    this.sku_addlocation = sku;
  }
}
