// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Contains utility and helper functions.
import Cookies from 'js-cookie';

import { Platform } from './enums/platform.enum';
import { ServicesInterface } from './interfaces/ServicesInterface';

/**
 * Gets a query parameter value based on the variable name.
 * Returns undefined if there's no variable of that name.
 */
export function getQueryParamValue(name: string): string | undefined {
  const param = (new URL(window.location.href).searchParams.get(name) ?? '').trim();
  return param.length > 0 ? decodeURIComponent(param) : undefined;
}

/**
 * Extracts the client version out of the client version string passed to Add Location from the
 * client. Returns zero if the version cannot be extracted for whatever reason.
 */
export function extractClientVersion(clientVersion: string): number {
  // Desktop client versions are in the form Major.Minor.Build, e.g. 19.2.0
  // Check if this a new enough client to enable large area imports.
  if (clientVersion !== null && clientVersion !== undefined) {
    const matches = clientVersion.match(/(\d+).(\d+).(\d+)/);
    if (matches !== null && matches !== undefined && matches.length === 4) {
      // A match will have 4 items, first one being the full string match and 3
      // capture groups for the major, minor and build numbers. We need to check
      // if this is version 19.2 or later. So turn this into a decimal floating
      // point value to compare it easily.
      // Example: If the version string is '19.1.0', then the 'matches' array
      // will contain ['19.1.0', '19', '1', '0']. We compute 'version' as:
      // 19 + 1 / 10.0 = 19.1
      return parseInt(matches[1], 10) + parseInt(matches[2], 10) / 10.0;
    }
  }
  return 0;
}

/**
 * Checks if the client version is permitted to allow a specific feature.
 *
 * @param clientVersion the current client version (supplied by the client)
 * @param minFeatureVersion the minimum client version where the feature should be permitted.
 */
export function clientVersionAllowsFeature(minFeatureVersion: number, clientVersion?: string): boolean {
  if (clientVersion !== null && clientVersion !== undefined) {
    const version: number = extractClientVersion(clientVersion);
    return version >= minFeatureVersion;
  }
  return false;
}

export function getP11DeviceId(): string | null {
  // P11 sends the device ID to Analytics as a cookie called CWS_CLIENT_ID.
  // AddLocation should have access to this cookie.
  return Cookies.get('CWS_CLIENT_ID');
}

export function getP11SessionId(): number | null {
  const sessionId = getQueryParamValue('session_id');
  return sessionId != null ? parseInt(sessionId) : null;
}

export function getSessionId(services: ServicesInterface, platform: Platform): number | null {
  const isDesktop = platform === Platform.Desktop;
  return isDesktop ? services.session_id : getP11SessionId();
}

export function getDeviceId(services: ServicesInterface, platform: Platform): string | null {
  const isDesktop = platform === Platform.Desktop;
  return isDesktop ? services.cid : getP11DeviceId();
}

export function getClientMajorVersion(client_version: string): number | undefined {
  try {
    const re = new RegExp(/^\d+/);
    const result = re.exec(client_version);
    return parseInt(result[0]);
  } catch (e) {
    console.error(`Failed to extract ${client_version}`, e);
    return undefined;
  }
}
