import { UserPreferencesStorageKeysEnum } from '../enums/user-preferences-storage-keys.enum';

export class UserPreferenceService {
  static get tileGridPreference(): boolean | undefined {
    const storedPreference = localStorage.getItem(UserPreferencesStorageKeysEnum.TILE_GRID_TOGGLE);
    return storedPreference === null ? undefined : storedPreference === 'true';
  }

  static set tileGridPreference(preference: boolean) {
    localStorage.setItem(UserPreferencesStorageKeysEnum.TILE_GRID_TOGGLE, String(preference));
  }

  static get importLevel(): number | undefined {
    const value = localStorage.getItem(UserPreferencesStorageKeysEnum.IMPORT_LEVEL);
    const parsedValue = parseInt(value);
    const importLevel = value === null || isNaN(parsedValue) ? undefined : parsedValue;

    const maxImportLevel = 20;
    if (importLevel && importLevel > maxImportLevel) {
      UserPreferenceService.importLevel = maxImportLevel;
      return maxImportLevel;
    }

    return importLevel;
  }

  static set importLevel(value: number) {
    localStorage.setItem(UserPreferencesStorageKeysEnum.IMPORT_LEVEL, String(value));
  }

  static reset(key: UserPreferencesStorageKeysEnum) {
    localStorage.removeItem(key);
  }
}
