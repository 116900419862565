export enum OSName {
  Windows = 'Windows',
  Mac = 'macOS',
  Chrome = 'ChromeOS',
  Linux = 'Linux',
  Xbox = 'Xbox',
  iOS = 'iOS',
  Android = 'Android',
  Playstation = 'Playstation',
  UWP = 'Universal Windows Platform',
}
