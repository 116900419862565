
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Tooltip extends Vue {
  @Prop({ required: false }) classes: string;
  @Prop({ required: true }) showTooltip: boolean = false;

  get tooltipClasses(): string {
    return this.classes ?? '';
  }
}
