
import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import { Token } from '../typescript/inject-tokens';
import { TrackingEventEnum } from '../typescript/tracking/tracking-event.enum';
import { TrackingService } from '../typescript/tracking/tracking.service';

@Component({})
export default class HelpButton extends Vue {
  @Prop({ required: true }) lang: string;

  @Inject(Token.TrackingService) trackingService!: TrackingService;

  openTrackedLink(): void {
    window.open(
      `https://help.sketchup.com/${this.lang === 'ru' ? 'en' : this.lang}/add-location`,
      '_blank',
      'noopener',
    );
    this.trackingService.send(TrackingEventEnum.ClickedShowHelp);
  }
}
