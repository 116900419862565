import { Logger } from '../Logger';

import { TrackingEventEnum } from './tracking-event.enum';
import { TrackingServiceInterface } from './tracking-service.interface';
import { CDPEvent } from './cdp-event';

export class CdpService implements TrackingServiceInterface {
  constructor(
    readonly analytics_url: string,
    readonly license_number: number,
    readonly deviceId: string,
    readonly sessionId: number = Date.now(),
    private readonly logger: Logger,
  ) {}

  send(event: TrackingEventEnum, properties: object | undefined = {}): void {
    try {
      const data = this.createCDPEvent(event, properties);
      const debugInformation = `event [${event}] with properties [${JSON.stringify(data)}]`;

      this.sendEvent(data)
        .then(() => console.debug(`Sent to CDP: ${debugInformation}`))
        .catch((error: unknown) => {
          console.warn(`Couldn't send analytics to CDP, request failed with error: ${error}`);
        });
    } catch (e) {
      console.warn(`Failed to send event ${event} to CDP: ${e}`);
    }
  }

  async sendAsync(event: TrackingEventEnum, properties: object): Promise<void> {
    try {
      const data = this.createCDPEvent(event, properties);
      const debugInformation = `event [${event}] with properties [${JSON.stringify(data)}]`;
      await this.sendEvent(data);
      console.debug(`Sent to CDP: ${debugInformation}`);
    } catch (e) {
      console.warn(`Failed to send event ${event} to CDP: ${e}`);
    }
  }

  private createCDPEvent(event: TrackingEventEnum, properties: object | undefined = {}): CDPEvent {
    return {
      app_name: 'add-location',
      event_type: event,
      event_properties: { ...properties, license_number: this.license_number },
      api_version: 'v2',
      session_id: this.sessionId,
      device_id: this.deviceId,
    };
  }

  private async sendEvent(data: CDPEvent): Promise<unknown> {
    const response = await fetch(this.analytics_url, <RequestInit>{
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(data),
    });
    return response.json();
  }
}
