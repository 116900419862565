import { AppSettingsStorageKeysEnum } from '../enums/app-settings-storage-keys.enum';

export class AppSettingsService {
  /**
   *  Checks localStorage to determine if this is a first time user
   */
  static get isFirstTimeUser(): boolean {
    const isFirstTimeUser = localStorage.getItem(AppSettingsStorageKeysEnum.FIRST_TIME_USER) !== 'false';
    if (isFirstTimeUser) {
      AppSettingsService.firstTimeUser = false;
    }
    return isFirstTimeUser;
  }

  static set firstTimeUser(value: boolean) {
    localStorage.setItem(AppSettingsStorageKeysEnum.FIRST_TIME_USER, String(value));
  }
}
