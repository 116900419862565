export class StorageVersion {
  static execAndBump(fn: Function): void {
    if (typeof fn !== 'function') {
      return;
    }
    if (this.storedVersion === this.version) {
      return;
    }

    fn();
    this.store();
  }

  private static readonly version = '0.0.0';

  private static readonly STORAGE_KEY = 'storageVersion';

  private static get storedVersion(): string | null {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  private static store(): void {
    localStorage.setItem(this.STORAGE_KEY, this.version);
  }
}
