import { Logger } from '../Logger';

import { GainsightIdentity } from './gainsight-identity.interface';
import { TrackingEventEnum } from './tracking-event.enum';
import { TrackingServiceInterface } from './tracking-service.interface';

export class GainsightService implements TrackingServiceInterface {
  private wasPreviouslyIdentified = false;

  private readonly scriptName: string = 'aptrinsic';

  constructor(
    private readonly identity: GainsightIdentity,
    private readonly logger: Logger,
  ) {
    this.identify();
  }

  identify(): void {
    if (this.aptrinsic) {
      try {
        if (this.wasPreviouslyIdentified) {
          this.aptrinsic('reset');
        }
        this.aptrinsic('identify', this.identity);
        this.wasPreviouslyIdentified = true;
      } catch (error) {
        this.logger.logExceptionMessage(`aptrinsic encountered an error during user identification ${error}`);
      }
    } else {
      this.logger.logExceptionMessage('aptrinsic is not defined. GAINSIGHT SDK failed to load?');
    }
  }

  send(event: TrackingEventEnum, properties: object | undefined = {}): void {
    const debugInformation = `event [${event}] with properties [${JSON.stringify(properties)}]`;
    if (this.aptrinsic) {
      try {
        this.aptrinsic('track', event, properties);
        console.debug(`Sent to GAINSIGHT: ${debugInformation}`);
      } catch (error) {
        this.logger.logExceptionMessage(`Failed to send event ${event} to GAINSIGHT, ${error}`);
      }
    } else {
      this.logger.logExceptionMessage(`aptrinsic is not available. Unable to send ${debugInformation}`);
    }
  }

  async sendAsync(event: TrackingEventEnum, properties: object): Promise<void> {
    this.send(event, properties);
  }

  private get aptrinsic(): Function {
    return window[this.scriptName];
  }
}
