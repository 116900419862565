// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Contains non-leaflet dependant geometrical/geospatial/spherical mercator operations.
// Several formulas adapted from Skore:
// https://bitbucket.trimble.tools/projects/SU/repos/skore/  (URL cont'd on next line)
// browse/common/geoutils/sphericalmercator.cpp

const EARTH_RADIUS_METERS = 6378137;
export const EARTH_CIRCUMFERENCE_METERS = 2.0 * Math.PI * EARTH_RADIUS_METERS;

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function radiansToDegrees(radians: number): number {
  return radians * (180 / Math.PI);
}

/**
 * Converts latitude degrees into meters (spherical mercator location on y axis).
 */
export function convertLatToMeters(latitude: number): number {
  if (latitude < -90) {
    // Less than -89.9 returns infinity.
    latitude = -89.9;
  } else if (latitude > 90) {
    latitude = 90;
  }

  return EARTH_RADIUS_METERS * Math.log(Math.tan(Math.PI / 4 + degreesToRadians(latitude) / 2));
}

/**
 * Converts longitude degrees into meters (spherical mercator location on x axis).
 */
export function convertLngToMeters(longitude: number): number {
  if (longitude < -180) {
    longitude = -180;
  } else if (longitude > 180) {
    longitude = 180;
  }

  return degreesToRadians(longitude) * EARTH_RADIUS_METERS;
}

/**
 * Converts meters (spherical mercator location on y axis) into degrees latitude.
 */
export function convertMetersToLat(y_meters: number): number {
  return radiansToDegrees(2 * Math.atan(Math.exp(y_meters / EARTH_RADIUS_METERS)) - Math.PI / 2);
}

/**
 * Converts meters (spherical mercator location on x axis) into degrees longitude.
 */
export function convertMetersToLng(x_meters: number): number {
  return radiansToDegrees(x_meters / EARTH_RADIUS_METERS);
}
