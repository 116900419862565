import * as Bowser from 'bowser';
import * as platform from 'platform';

import { OSName } from '../enums/os-name.enum';
import { BrowserDetailsModel } from '../models/browser-details-model';

export class BrowserDetailsService {
  static browserDetailsForAgent(userAgent: string): BrowserDetailsModel {
    const details = Bowser.getParser(userAgent);
    let platformDetails: any;
    if (platform.parse !== null) {
      platformDetails = platform.parse(userAgent);
    }

    return new BrowserDetailsModel(
      details.getBrowserName(),
      details.getBrowserVersion(),
      BrowserDetailsService.normalizeOsName(details.getOSName()),
      details.getOSVersion(),
      platformDetails && platformDetails.product ? platformDetails.product : undefined,
      platformDetails && platformDetails.manufacturer ? platformDetails.manufacturer : undefined,
    );
  }

  // taken from https://bitbucket.trimble.tools/projects/SU/repos/sketchup_web/browse/frontend/typescript/Analytics.ts#295
  private static normalizeOsName(osName: string): OSName | null {
    if (osName === null) {
      return null;
    }
    osName = osName.toLowerCase();
    if (osName.includes('windows')) {
      return OSName.Windows;
    } else if (osName.includes('macos')) {
      return OSName.Mac;
    } else if (osName.includes('chrome')) {
      return OSName.Chrome;
    } else if (osName.includes('linux')) {
      return OSName.Linux;
    } else if (osName.includes('ios')) {
      return OSName.iOS;
    } else if (osName.includes('android')) {
      return OSName.Android;
    } else {
      return null;
    }
  }
}
