import { GeocoderResponse, ReverseGeocoderResponse } from '../GeocoderResponse';
import * as login from '../Login';
import { ActivationsResponseDto } from '../entitlements/activations-response.dto';

export class AddLocationApiService {
  static async reverseGeocode(lng: number, lat: number, language: string): Promise<ReverseGeocoderResponse> {
    const url = `/addlocation/v1.0/rgeocode?loc=${lng},${lat}&hl=${language}`;
    let reverseGeocodeData: ReverseGeocoderResponse;
    try {
      reverseGeocodeData = await AddLocationApiService.doGET(url);
    } catch (e) {
      reverseGeocodeData = {
        City: '',
        Country: '',
      };
    }
    return reverseGeocodeData;
  }

  static async geolocate(): Promise<{ latitude: number; longitude: number }> {
    return AddLocationApiService.doGET('/addlocation/v1.0/geolocate');
  }

  static async geocode(address: string, language: string): Promise<Array<GeocoderResponse>> {
    const url = `/addlocation/v1.0/geocode?search=${encodeURIComponent(address)}&hl=${language}`;
    return AddLocationApiService.doGET(url);
  }

  static async getLargeAreaImportEntitlement(): Promise<ActivationsResponseDto> {
    if (await login.isLoggedIn()) {
      try {
        const response = await AddLocationApiService.doGET('/addlocation/v1.0/large-area-import-activations');
        if (response.errorCode !== 'NO_ERROR') {
          console.log('Error occurred while retrieving entitlement. Error code: ', response.errorCode);
        }
        return response;
      } catch (e) {
        if (e !== 204) {
          console.log('Error occurred while retrieving entitlements.', JSON.stringify(e, null, 2));
        }
      }
    }
    return {
      errorCode: 'UNKNOWN_ERROR',
      activations: [],
      tid: 'unknown',
      currentSubscription: '',
      trialAvailable: false,
    };
  }

  /**
   * Does a GET request and returns a promise which resolves with
   * the response JSON.
   */
  static doGET(url: string): Promise<any> {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open('GET', url, true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const responseJSON = JSON.parse(xhr.response);
          resolve(responseJSON);
        } else {
          reject(xhr.status);
        }
      };
      xhr.onerror = error => {
        reject(error);
      };
      xhr.send();
    });
  }
}
