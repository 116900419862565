import { Platform } from '../enums/platform.enum';
import { Stacks } from '../enums/stacks.enum';

import { ActivationDto, ActivationsResponseDto } from './activations-response.dto';
import { AddlocationFeatureIds } from './addlocation-feature-ids.enum';

type ActivationResponse =
  | { status: 401 | 403 }
  | {
      status: 200;
      data: ActivationsResponseDto;
    };

// temporary interface whilst we try to work out the
export class EntitlementsService {
  private readonly responseCache: Map<string, ActivationResponse> = new Map();

  constructor(
    private readonly stack: Stacks,
    private readonly platform: Platform,
  ) {}

  /**
   * Used only for reporting, extract the most recent activation for the user
   */
  async getUserActivation(): Promise<ActivationDto | undefined> {
    const activations = await this.getActivations('FEA-SKP-AL');
    if (activations.length === 0) {
      return undefined;
    } else {
      return EntitlementsService.getLatestActivation(activations);
    }
  }

  private async getActivations(featureFilter: string): Promise<Array<ActivationDto>> {
    const response = await this.fetchActivations(featureFilter);
    if (response.status !== 200) {
      throw new Error(
        `Error retrieving entitlements at url=${this.getActivationUrl(featureFilter)}, unexpected response code ${
          response.status
        }`,
      );
    }

    const validActivations: Array<ActivationDto> = EntitlementsService.extractValidActivations(response.data);
    const anyActivation: Array<ActivationDto> = response.data.activations;
    return validActivations.length ? validActivations : anyActivation;
  }

  async getActivatedFeatures(): Promise<Set<string>> {
    if (this.platform === Platform.Schools) {
      // schools does not require a TID login, and no students will have any entitlements
      return new Set();
    }

    return await this.getActivationFeatures('FEA-SKP-AL');
  }

  private async getActivationFeatures(featureFilter: string): Promise<Set<string>> {
    const response = await this.fetchActivations(featureFilter);
    if (response.status !== 200) {
      return new Set();
    }

    return EntitlementsService.extractValidFeatureIds(response.data);
  }

  static getLatestActivation(activations: Array<ActivationDto>): ActivationDto {
    return activations.sort(
      (a: ActivationDto, b: ActivationDto) =>
        new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime(),
    )[0];
  }

  static extractValidActivations(activationsResponse: ActivationsResponseDto): Array<ActivationDto> {
    const validActivations = activationsResponse.activations.filter(
      activation => activation.authorized && (activation.daysLeft === -1 || activation.daysLeft > 0),
    );
    return activationsResponse.errorCode === 'NO_ERROR' && validActivations.length > 0 ? validActivations : [];
  }

  static extractValidFeatureIds(activationsResponse: ActivationsResponseDto): Set<string> {
    const validActivations = this.extractValidActivations(activationsResponse);
    if (validActivations.length) {
      const featureIds = validActivations
        .map(activation => {
          const bestFeatureId = activation.featureId ? [activation.featureId] : [];
          const allFeatureIds = activation.allFeatureIds ? activation.allFeatureIds : [];
          return bestFeatureId.concat(allFeatureIds);
        })
        .reduce((a, b) => a.concat(b));
      return new Set(featureIds);
    } else {
      return new Set();
    }
  }

  private getActivationUrl(featureFilter: string): string {
    return `${this.getBaseUrl(this.stack)}/activations?featureFilter=${featureFilter}`;
  }

  private async fetchActivations(featureFilter: string): Promise<ActivationResponse> {
    if (this.responseCache.has(featureFilter)) {
      // use the cached response
      return this.responseCache.get(featureFilter);
    }

    const url = this.getActivationUrl(featureFilter);
    const response = await fetch(url, {
      credentials: 'include',
      headers: [['Accept', 'application/json']],
    });

    let activationResponse: ActivationResponse;
    if (response.ok) {
      activationResponse = {
        status: 200,
        data: await response.json(),
      };

      // hack to prevent trial users from having lower access than they should
      activationResponse.data.activations.forEach(activation => {
        if (activation.isTrial && activation.featureId === AddlocationFeatureIds.Silver) {
          // due to a configuration issue, the platinum trials may have silver treat them as
          activation.featureId = AddlocationFeatureIds.Platinum;
        }
      });
    } else if (response.status === 401 || response.status === 403) {
      // not logged in. we can cache this
      activationResponse = { status: response.status };
    } else {
      throw new Error(`Error retrieving entitlements at url=${url}, unexpected response code ${response.status}`);
    }

    this.responseCache.set(featureFilter, activationResponse);
    return activationResponse;
  }

  // Entitlements url for different stacks.
  private getBaseUrl(stack: Stacks): string {
    switch (stack) {
      case Stacks.Dev:
        return 'https://dev-api.sketchup.com/entitlements/v2';
      case Stacks.Stg:
        return 'https://stg-api.sketchup.com/entitlements/v2';
      default:
        return 'https://api.sketchup.com/entitlements/v2';
    }
  }
}
