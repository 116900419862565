import * as Leaflet from 'leaflet';

import { MapExtended } from './interfaces/map-extended.interface';

export class MapDecorations {
  private container: HTMLElement;
  /**
   * The fixedMapPin is used to add a pin to a specific location
   * and the defaultMapPin it's used to show a map pin that will always be in the center of the map.
   * htmlElement is used for the defaultMapPin to display it as an html element positioned in the center
   * instead of having to listen for map movement and update it every time so it stays centered.
   */
  private fixedMapPin: Leaflet.Marker;
  private defaultMapPin: HTMLElement;

  constructor(private readonly map: MapExtended) {
    this.container = Leaflet.DomUtil.create('div', 'decorations-container', this.map._controlContainer);
    this.showCenteredMapPin();
  }

  /**
   * Adds an map marker to a specific location
   */
  addMapPin(origin: Leaflet.LatLng): void {
    if (this.fixedMapPin === undefined) {
      this.defaultMapPin.remove();
      const mapPinIcon = Leaflet.icon({
        iconUrl: '/static/images/map-pin.png',
        iconSize: [25, 39],
        iconAnchor: [12.5, 39],
      });
      this.fixedMapPin = Leaflet.marker(origin, { icon: mapPinIcon }).addTo(this.map);
    }
  }

  /**
   * Removes the fixed map pin and shows the default map pin that it's centered
   */
  removeMapPin(): void {
    if (this.fixedMapPin !== undefined) {
      this.fixedMapPin.remove();
      this.showCenteredMapPin();
    }
  }

  remove(): void {
    this.container.parentNode.removeChild(this.container);
  }

  /**
   * Shows a map pin that it's always centered
   */
  private showCenteredMapPin(): void {
    if (this.fixedMapPin !== undefined) {
      this.fixedMapPin.remove();
    }
    if (this.defaultMapPin === undefined) {
      this.defaultMapPin = Leaflet.DomUtil.create('div', 'decorations-map-pin', this.container);
    }
  }
}
