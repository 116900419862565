
import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';

import { AppFeatures } from '../typescript/application-features';
import EventBus from '../typescript/EventBus';
import { Token } from '../typescript/inject-tokens';
import * as I10n from '../typescript/l10n';
import { MapTypeEnum } from '../typescript/map/enums/map-type.enum';
import { TrackingEventEnum } from '../typescript/tracking/tracking-event.enum';
import { TrackingService } from '../typescript/tracking/tracking.service';

import Checkbox from './checkbox.vue';

@Component({
  components: {
    Checkbox,
  },
})
export default class MapTypeComponent extends Vue {
  @Prop({ required: true }) appFeatures: AppFeatures;
  @Prop({ required: true }) selectedMapType: MapTypeEnum;

  @Inject(Token.TrackingService) trackingService!: TrackingService;

  readonly MapTypeEnum = MapTypeEnum;
  readonly EventBus = EventBus;

  readonly mapTypeLayerImages = [
    {
      image: '/static/images/satellite-view-icon-v2.png',
      layerName: I10n.$t('Satellite'),
      layerType: MapTypeEnum.satellite,
    },
    {
      image: '/static/images/street-view-icon-v2.png',
      layerName: I10n.$t('Street map'),
      layerType: MapTypeEnum.streetMap,
    },
  ];

  changeMapType(mapType: MapTypeEnum): void {
    this.trackingService.send(TrackingEventEnum.ChangedMapType, { mapType });
    EventBus.$emit('update:mapType', mapType);
  }
}
