// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: Cole Carroll (cole@sketchup.com)
// Contains a class that manages creation and formatting of Add Location user analytics events.
import { MapTileProviderEnum } from '../map/enums/map-tile-provider.enum';
import { clientVersionAllowsFeature, extractClientVersion } from '../Utils';
import * as AnalyticsAPI from './Analytics';
import {
  AddLocationEventTypes,
  ClosedAddLocationProperties,
  EVENT_SCHEMA_VERSION,
  ImportProperties,
  ImportPropertiesDependencies,
  LaunchedAddLocationProperties,
  TileProviderTypes,
} from './AnalyticsInterfacesV1';
import { CDPEvent } from '../tracking/cdp-event';

export type AddLocationClients = 'su-web' | 'su-desktop' | 'su-mobile' | 'add-location';
/**
 * Class that handles sending CDP events from Add Location to the Analytics Service.
 */
export class CDPAnalyticsHandler {
  private clientName: AddLocationClients;
  private importPropertiesObj: ImportProperties;
  /**
   * SketchUp Desktop clients prior to 20.1 and P11 will send add location CDP events into their
   * own Amplitude bucket. This application name is the trigger which indicates they should be
   * sent into that bucket.
   */
  /**
   * The SketchUp Desktop release that introduced the work needed to be able to include CDP events
   * directly in the Desktop Client's event stream.
   */
  private readonly desktopCDPEnabledReleaseVersion = 20.1;
  /**
   * A unique id that represents the device on which the user is operating the Desktop Client. In
   * Desktop Client versions 20.1 and later, the Desktop Client provides this for us so that CDP
   * events Add Location sends to Amplitude will be correctly linked to a user. In prior versions,
   * or in P11, we don't include this in events. The Analytics service will set it on the client
   * in a cookie.
   */
  private readonly deviceId: string;
  /**
   * A user's license number.
   * This will be 0 for anyone without a perpetual license
   */
  private readonly license_number: number;
  /**
   * A unique id for the Add Location session derived from number of milliseconds since epoch. In
   * Desktop Client versions 20.1 and later, the Desktop Client provides this for us so that CDP
   * events Add Location sends to Amplitude show up in the same event stream as those from the
   * Desktop client. In prior versions, or in P11 we generate this ourselves.
   */
  private readonly sessionId: number;
  /**
   * The version string of the Desktop Client running Add Location.
   */
  private readonly desktopClientVersion: string;

  private readonly sku: string;

  constructor(
    clientName: AddLocationClients,
    license_number: number,
    sku: string,
    clientVersionString?: string,
    deviceId?: string,
    sessionId?: number,
  ) {
    this.clientName = clientName;
    this.deviceId = deviceId;
    this.license_number = license_number;
    // Session Id is always milliseconds elapsed since Epoch, which Date.now() returns.
    this.sessionId = sessionId || Date.now();

    if (this.clientName === 'su-desktop') {
      this.desktopClientVersion = clientVersionString;
    }
    this.sku = sku;
  }

  /**
   * Sends a Launched Add Location user event to the Analytics Service for processing.
   */
  async sendLaunchedAppEvent(): Promise<void> {
    const event = this.toCDPEvent(AddLocationEventTypes.LaunchedApp);
    await this.sendEvent(event);
  }

  /**
   * Sends a Closed Add Location user event to the Analytics Service for processing.
   * The 'withImport' boolean parameter indicates whether or not the application is closing because
   * of an import or if the user simply closed the Add Location window. Clients of Add Location
   * automatically close the Add Location window when an import occurs.
   */
  sendClosedAppEvent(withImport: boolean): void {
    const event = this.toCDPEvent(AddLocationEventTypes.ClosedApp);
    if (withImport) {
      this.sendEventNoWait(event);
    } else {
      AnalyticsAPI.sendClosedAppWithoutImportEvent(event, this.clientName);
    }
  }

  /**
   * Sends a Import Event user event to the Analytics Service for processing.
   */
  sendImportEvent(): void {
    if (this.importPropertiesObj != null) {
      const event = this.toCDPEvent(AddLocationEventTypes.Import);
      this.sendEventNoWait(event);
    }
  }

  /**
   * Sends a Cancel Import user event to the Analytics Service for processing.
   */
  sendImportCancelEvent(): void {
    if (this.importPropertiesObj != null) {
      const event = this.toCDPEvent(AddLocationEventTypes.CancelImport);
      this.sendEventNoWait(event);
    }
  }

  private sendEventNoWait(event: CDPEvent): void {
    this.sendEvent(event).catch(_ignore => {});
  }

  private async sendEvent(event: CDPEvent): Promise<void> {
    try {
      await AnalyticsAPI.sendEvent(event);
    } catch (e) {
      // For now, fire and forget.
    }
  }

  /**
   * Creates the event object for all Add Location events.
   */
  private toCDPEvent(eventType: AddLocationEventTypes): CDPEvent {
    let eventProps: any;
    if (eventType === AddLocationEventTypes.LaunchedApp) {
      eventProps = this.createLaunchedAddLocationEventProperties();
    } else if (eventType === AddLocationEventTypes.ClosedApp) {
      eventProps = this.createClosedAddLocationEventProperties();
    } else {
      eventProps = this.importPropertiesObj;
    }

    // FE-967 - Capture "Licence Number" from Add Location users >> https://jira.trimble.tools/browse/FE-967
    eventProps = { ...eventProps, license_number: this.license_number };

    if (
      this.clientName !== 'su-desktop' ||
      (this.desktopClientVersion != null &&
        clientVersionAllowsFeature(this.desktopCDPEnabledReleaseVersion, this.desktopClientVersion))
    ) {
      return {
        app_name: this.clientName,
        event_type: eventType,
        event_properties: eventProps,
        api_version: 'v2',
        session_id: this.sessionId,
        device_id: this.deviceId,
      };
    }

    // For events on clients prior to SketchUp 20.1 we do not want to send the event into the more specific application
    return {
      app_name: 'add-location',
      event_type: eventType,
      event_properties: eventProps,
      api_version: 'v2',
      session_id: this.sessionId,
      // device_id is set by the analytics service in cookies.
    };
  }

  /**
   * Creates the event properties object for Launched Add Location events.
   */
  private createLaunchedAddLocationEventProperties(): LaunchedAddLocationProperties {
    const clientVersion = extractClientVersion(this.desktopClientVersion || '');
    return {
      sku: this.sku,
      event_schema_version: EVENT_SCHEMA_VERSION,
      client_version: clientVersion !== 0 ? clientVersion : null,
    };
  }

  /**
   * Creates the event properties for Closed Add Location events.
   */
  private createClosedAddLocationEventProperties(): ClosedAddLocationProperties {
    return {
      event_schema_version: EVENT_SCHEMA_VERSION,
    };
  }

  /**
   * Sets an object with all the needed event properties for Import Event and Cancel Import
   * events on the instance.
   */
  setImportPropertiesObj(importPropertiesDependencies: ImportPropertiesDependencies): void {
    let city;
    let state;
    // Find the comma in the cityState string.
    const cityStateSeperatorIndex = importPropertiesDependencies.cityState.indexOf(',');
    if (cityStateSeperatorIndex > -1) {
      // Comma is present in string, meaning a state has been included. Assign parts of the string
      // to the appropriate variables.
      city = importPropertiesDependencies.cityState.substring(0, cityStateSeperatorIndex);
      state = importPropertiesDependencies.cityState.substring(cityStateSeperatorIndex + 1).trim();
    } else {
      // No state included. CityState string will just be a city or possibly an empty string.
      city = importPropertiesDependencies.cityState;
      state = null;
    }

    this.importPropertiesObj = {
      event_schema_version: EVENT_SCHEMA_VERSION,
      import_level: importPropertiesDependencies.zoomForImport,
      import_type: importPropertiesDependencies.importType,
      origin_lat: importPropertiesDependencies.centerpoint.lat,
      origin_lng: importPropertiesDependencies.centerpoint.lng,
      tile_quantity: importPropertiesDependencies.tileQuantity,
      view_level: importPropertiesDependencies.zoom,
      city: city || null, // If empty string, send null.
      state: state,
      country: importPropertiesDependencies.country || null, // If empty string, send null.
    };
  }

  /**
   * Maps the active service name from the Add Location Page to a CDP Tile Provider value.
   */
  getTileProviderTypeFromMapProvider(provider: MapTileProviderEnum): TileProviderTypes {
    switch (provider) {
      case MapTileProviderEnum.satelliteBing:
        return TileProviderTypes.Bing;
      case MapTileProviderEnum.satelliteDigitalGlobe:
        return TileProviderTypes.DigitalGlobe;
      default:
        return TileProviderTypes.MapOnly;
    }
  }
}
