// Copyright 2018 - 2019 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)
// Determines which environment we are running in based on the window URI.

import { Stacks } from './enums/stacks.enum';

/**
 * We operate under the assumption that unless the tester specifically
 * declares a stack to use via that STACK_QUERY_PARAM, we are in production.
 */
export function getActiveEnvironment(): Stacks {
  switch (new URL(window.location.href).searchParams.get('use_stacks')) {
    case Stacks.Dev:
      return Stacks.Dev;
    case Stacks.Stg:
      return Stacks.Stg;
    case Stacks.Sandbox:
      return Stacks.Sandbox;
    case Stacks.Local:
      return Stacks.Local;
    default:
      return Stacks.Prod;
  }
}
