// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: matt@sketchup.com (Matt Gates)
// Does the formatting and building of the object sent to the clients to do an import.

/**
 * Represents a Lat/Long for import.
 */
export interface LatLong {
  // Latitude.
  lat: number;
  // Longitude.
  long: number;
}

/**
 * The import config used to build the the dat sent to the clients.
 */
export interface ImportConfig {
  // The center of the import.
  center: LatLong;
  //  The NE corner
  northEast: LatLong;
  // The index of the service.
  serviceIndex: number;
  //  The SW corner
  southWest: LatLong;
  // How many terrrain point to get.
  terrainPointCount: number;
  // A unique id for imports.
  tileImportId: string;
  // Whnether to use mulitple textures.
  useMultipleTextures: boolean;
  // Current zoom level of the bounds.
  zoom: number;
  // The level of detail to import.
  zoomForImport: number;
}

/**
 * The import settings sent to the clients (See docs on ImportConfig).
 */
export interface DataObjectForImport {
  ctr_lat: number;
  ctr_lng: number;
  ne_lat: number;
  ne_lng: number;
  sw_lat: number;
  sw_lng: number;
  zoom: number;
  zoom_for_import: number;
  terrain_point_count: number;
  tile_import_id: string;
  use_multiple_textures: boolean;
  city: string;
  country: string;
  s_idx: number;
}

/**
 * Builds the object sent to the clients to do an import.
 *
 * @param config The import confuration.
 */
export function buildDataObjectForImport(config: ImportConfig): DataObjectForImport {
  return {
    ctr_lat: formatLatForJson(config.center.lat),
    ctr_lng: formatLongForJson(config.center.long),
    ne_lat: formatLatForJson(config.northEast.lat),
    ne_lng: formatLongForJson(config.northEast.long),
    sw_lat: formatLatForJson(config.southWest.lat),
    sw_lng: formatLongForJson(config.southWest.long),
    zoom: config.zoom,
    zoom_for_import: config.zoomForImport,
    terrain_point_count: config.terrainPointCount,
    tile_import_id: config.tileImportId,
    use_multiple_textures: config.useMultipleTextures,
    city: '',
    country: '',
    s_idx: config.serviceIndex,
  };
}

/**
 * Formats a latitude for JSON.
 *
 * @param lat The latitude to format.
 */
function formatLatForJson(lat: number): number {
  return formatLatLongForJson(lat, 90);
}

/**
 * Formats a longtude for JSON.
 *
 * @param long The longtude to format.
 */
function formatLongForJson(long: number): number {
  return formatLatLongForJson(long, 180);
}

/**
 * Ensures that a lat long value always has decimal places to ensure that
 * SketchUp desktop clients prior to 2020 do not fail to import when the
 * Lat/Long coords are exact (i.e. 90 deg).
 *
 * @param latLong The Lat/Long to format.
 * @param max The max value the Lat/Long can be. This should be 90 for lats
 *     and 180 for longs.
 */
function formatLatLongForJson(latLong: number, max: number): number {
  let formattedLatLong = latLong;
  if (latLong % 1 === 0) {
    // Adds or subtracts a tiny number to ensure that the number will have a decimal place
    // when parsed to a float.
    // https://stackoverflow.com/questions/15965166
    // https://xkcd.com/2170/
    const VERY_SMALL_NUMBER = 1e-6;
    let newLatLong = latLong + VERY_SMALL_NUMBER;

    if (newLatLong > max) {
      newLatLong = latLong - VERY_SMALL_NUMBER;
    }
    formattedLatLong = newLatLong;
  }
  return formattedLatLong;
}
