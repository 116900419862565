// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: jakub@sketchup.com (Jakub Borowski)

import Vue from 'vue';

/**
 * Enables any code to directly listen for events emitted by our Vue components
 * without having to manually bubble them up to the appropriate listener.
 */
const EventBus = new Vue();

export default EventBus;
