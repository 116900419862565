import * as Leaflet from 'leaflet';

import { MapAreaSelect } from './map-area-select';
import { MapModel } from './map.model';

export class AreaSelectLayer {
  private areaSelect_: MapAreaSelect = undefined;

  constructor(private readonly mapModel: MapModel) {}

  update(enabled: boolean): void {
    if (enabled) {
      if (!this.areaSelect_) {
        const mapSize = this.mapModel.map.getSize();
        this.areaSelect_ = new MapAreaSelect(this.mapModel.map, mapSize.x / 2, mapSize.y / 2);
      }
    } else if (this.areaSelect_) {
      this.areaSelect_.remove();
      this.areaSelect_ = undefined;
    }
  }

  get isEnabled(): boolean {
    return !!this.areaSelect_;
  }

  get bounds(): Leaflet.LatLngBounds {
    return this.areaSelect_.bounds;
  }

  get areaSelect(): MapAreaSelect | undefined {
    return this.areaSelect_;
  }
}
