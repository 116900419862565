
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import EventBus from '../typescript/EventBus';

/**
 * Component to show a checkbox and a label.
 */
@Component({
  inheritAttrs: false,
})
export default class Checkbox extends Vue {
  /**
   * Whether the checkbox is checked.
   */
  @Prop({ default: false }) checked: boolean;

  /**
   * Optional - Whether the checkbox is disabled.
   */
  @Prop({ default: false }) disabled: boolean;

  /**
   * Optional - A label to indicate to user what implication of checking the checkbox are.
   */
  @Prop({ default: '' }) label: string;

  @Prop({ required: true }) toggleEventName: string;

  /**
   * Fires an event notifying the parent that the checkbox wants to toggle
   * the checked state. The desired checked state is sent.  It's up to the
   * parent to decide whether or not to toggle the checked property.
   */
  onToggle(): void {
    if (!this.disabled) {
      EventBus.$emit(this.toggleEventName, !this.checked);
    }
  }
}
