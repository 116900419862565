
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { AppFeatures } from '../typescript/application-features';
import { ImportDisabledReasonEnum } from '../typescript/enums/import-disabled-reason.enum';
import { ImportResolutionType } from '../typescript/enums/import-resolution-type.enum';
import { Platform } from '../typescript/enums/platform.enum';
import { ProviderDisabledReasonEnum } from '../typescript/enums/provider-disabled-reason.enum';
import EventBus from '../typescript/EventBus';
import { MapTypeEnum } from '../typescript/map/enums/map-type.enum';
import { SatelliteTileProviderEnum } from '../typescript/map/enums/satellite-tile-provider.enum';
import { MapModel } from '../typescript/map/map.model';

import Checkbox from './checkbox.vue';
import ImportDisabledReason from './import-disabled-reason.vue';
import ProviderSelect from './provider-select.vue';
import ResolutionSelect from './resolution-select.vue';
import UpsellInfo from './upsell.vue';

@Component({
  components: {
    ImportDisabledReason,
    ResolutionSelect,
    Checkbox,
    ProviderSelect,
    UpsellInfo,
  },
})
export default class RegionImportControls extends Vue {
  @Prop({ required: true }) appFeatures: AppFeatures;
  @Prop({ required: true }) platform: Platform;
  @Prop({ required: false }) map: MapModel;
  @Prop({ required: true }) importLevel: number;
  @Prop({ required: true }) minImportLevel: number;
  @Prop({ required: true }) regionImported: boolean;
  @Prop({ required: true }) regionSelected: boolean;
  @Prop({ required: true }) selectedTileProvider: SatelliteTileProviderEnum;
  @Prop({ required: true }) selectedMapType: MapTypeEnum;
  @Prop({ required: true }) shallowZoomLevel: boolean;
  @Prop({ required: true }) showTileGrid: boolean;
  @Prop({ required: true }) tilesSelectedQty: number;
  @Prop({ required: true }) zoomLevel: number;
  @Prop({ required: true }) zoomLevelMax: number;

  get tileProviderDisabledReasons(): Map<SatelliteTileProviderEnum, ProviderDisabledReasonEnum> {
    const disabledProviders = new Map<SatelliteTileProviderEnum, ProviderDisabledReasonEnum>();

    if (this.map) {
      const availableSatelliteTypes = this.map.availableSatelliteTypes;

      Object.values(SatelliteTileProviderEnum).forEach((satelliteProvider: SatelliteTileProviderEnum) => {
        if (!availableSatelliteTypes.has(satelliteProvider)) {
          disabledProviders.set(satelliteProvider, ProviderDisabledReasonEnum.notAvailable);
        } else {
          const mapTileProvider = MapModel.satelliteToMapTileProvider(satelliteProvider);
          const mapService = this.map.findMapServiceByProvider(mapTileProvider);
          if (this.importLevel > mapService.end_zoom) {
            disabledProviders.set(satelliteProvider, ProviderDisabledReasonEnum.resolutionNotSupported);
          }
        }
      });
    }

    return disabledProviders;
  }

  get isResolutionSelectSupported(): boolean {
    return (
      this.appFeatures.resolutionType === ImportResolutionType.UserSpecified &&
      this.selectedMapType === MapTypeEnum.satellite
    );
  }

  get isTileGridSupported(): boolean {
    return this.appFeatures.tileBoundaries && this.selectedMapType === MapTypeEnum.satellite;
  }

  get displayProviderSelect(): boolean {
    return this.appFeatures.satelliteProviderSelection && this.selectedMapType === MapTypeEnum.satellite;
  }

  get importResolutionNotSupported(): boolean {
    return this.importLevel && this.importLevel > this.map.activeTileProviderService.end_zoom;
  }

  get tooManyTilesSelected(): boolean {
    return this.tilesSelectedQty > MapModel.MAX_TILE_IMPORT_QUANTITY;
  }

  get isNotEntitledToImportSatellite(): boolean {
    return !this.appFeatures.satelliteImport && this.selectedMapType === 'satellite';
  }

  get isNotEntitledToImportBing(): boolean {
    return (
      this.appFeatures.satelliteBing === false &&
      this.selectedTileProvider === SatelliteTileProviderEnum.bing &&
      this.selectedMapType === 'satellite'
    );
  }

  get isNotEntitledToImport(): boolean {
    return this.isNotEntitledToImportSatellite || this.isNotEntitledToImportBing;
  }

  get importDisabledReason(): ImportDisabledReasonEnum | undefined {
    if (this.importResolutionNotSupported) {
      return ImportDisabledReasonEnum.resolutionNotSupported;
    } else if (this.tooManyTilesSelected) {
      return ImportDisabledReasonEnum.tooManyTiles;
    }
    return undefined;
  }

  onSelect(): void {
    EventBus.$emit('update:selectRegion');
  }

  onCancelSelect(): void {
    EventBus.$emit('update:cancelSelectRegion');
  }

  onImport(): void {
    // prevent the import if the user is not entitled to and tries to force it(by removing button disabled attribute via dev tools)
    if (this.isNotEntitledToImport || this.regionImported || this.importDisabledReason !== undefined) {
      return;
    }
    EventBus.$emit('update:importRegion');
  }

  onImportZoomLevelChange(event: Event): void {
    const currentLayerIndex = parseInt((<HTMLInputElement>event.target).value);
    EventBus.$emit('update:importLevel', currentLayerIndex);
  }
}
