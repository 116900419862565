
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ImportDisabledReasonEnum } from '../typescript/enums/import-disabled-reason.enum';
import { MapTileProviderEnum } from '../typescript/map/enums/map-tile-provider.enum';
import { MapModel } from '../typescript/map/map.model';

@Component({
  components: {},
})
export default class ImportDisabledReason extends Vue {
  @Prop({ required: true })
  reason: ImportDisabledReasonEnum;

  @Prop({ required: true })
  activeTileProvider: MapTileProviderEnum;

  // expose these to the template
  readonly MapModel = MapModel;

  reasons = ImportDisabledReasonEnum;
  tileProviders = MapTileProviderEnum;
}
