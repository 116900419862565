import * as Leaflet from 'leaflet';

import { AreaHandle } from './interfaces/area-handle.interface';
import { AreaSize } from './interfaces/area-size.interface';
import { MapExtended } from './interfaces/map-extended.interface';

export class MapAreaSelect {
  private width: number = 200;
  private height: number = 300;
  private keepAspectRatio: boolean = false;

  private container: HTMLElement;
  private areaShade: HTMLElement;
  private areaHandle: AreaHandle;

  private readonly areaHandleWarnClass: string = 'bg-danger';

  constructor(
    private readonly map: MapExtended,
    _width: number,
    _height: number,
  ) {
    this.width = _width;
    this.height = _height;
    this.createElements();
  }

  get bounds(): Leaflet.LatLngBounds {
    const size = this.map.getSize();
    const topRight = new Leaflet.Point(0, 0);
    const bottomLeft = new Leaflet.Point(0, 0);

    bottomLeft.x = Math.round((size.x - this.width) / 2);
    topRight.y = Math.round((size.y - this.height) / 2);
    topRight.x = size.x - bottomLeft.x;
    bottomLeft.y = size.y - topRight.y;

    const sw = this.map.containerPointToLatLng(bottomLeft);
    const ne = this.map.containerPointToLatLng(topRight);

    return new Leaflet.LatLngBounds(sw, ne);
  }

  remove(): void {
    this.map.off('moveend', this.onMapChange);
    this.map.off('zoomend', this.onMapChange);
    this.map.off('resize', this.onMapResize);
    this.map.off('areaSelectChange');
    this.container.parentNode.removeChild(this.container);
  }

  private createElements(): void {
    if (this.container) {
      return;
    }

    this.container = Leaflet.DomUtil.create('div', 'leaflet-areaselect-container', this.map._controlContainer);
    this.areaShade = this.createAreaShade();
    this.areaHandle = this.createAreaHandle();

    this.setUpHandlerEvents(this.areaHandle.nw);
    this.setUpHandlerEvents(this.areaHandle.ne, -1, 1);
    this.setUpHandlerEvents(this.areaHandle.sw, 1, -1);
    this.setUpHandlerEvents(this.areaHandle.se, -1, -1);

    this.addMapListeners();
    this.render();
    this.map.fire('areaSelectChange');
  }

  private addMapListeners(): void {
    this.map.on('moveend', this.onMapChange, this);
    this.map.on('zoomend', this.onMapChange, this);
    this.map.on('resize', this.onMapResize, this);
  }

  private onMapChange(): void {
    this.map.fire('areaSelectChange');
  }

  private createAreaShade(): HTMLElement {
    return Leaflet.DomUtil.create('div', 'leaflet-areaselect leaflet-control', this.container);
  }

  private createAreaHandle(): AreaHandle {
    return {
      nw: Leaflet.DomUtil.create('div', 'leaflet-areaselect-handle leaflet-control', this.container),
      sw: Leaflet.DomUtil.create('div', 'leaflet-areaselect-handle leaflet-control', this.container),
      ne: Leaflet.DomUtil.create('div', 'leaflet-areaselect-handle leaflet-control', this.container),
      se: Leaflet.DomUtil.create('div', 'leaflet-areaselect-handle leaflet-control', this.container),
    };
  }

  private setUpHandlerEvents(handle: HTMLElement, xMod: number = 1, yMod: number = 1): void {
    const onHandleSelect = (event: MouseEvent | TouchEvent) => {
      Leaflet.DomEvent.removeListener(handle, 'mousedown touchstart', onHandleSelect);
      event.stopPropagation();
      this.map.dragging.disable();

      const initialEventPosition = this.eventPosition(event);
      const curX = initialEventPosition.pageX;
      const curY = initialEventPosition.pageY;
      const ratio = this.width / this.height;
      const size = this.map.getSize();
      const origWidth = this.width;
      const origHeight = this.height;

      const onHandleMove = (event: MouseEvent | TouchEvent) => {
        const currentEventPosition = this.eventPosition(
          event.type === 'mousemove' ? (<any>event).originalEvent : event,
        );
        if (this.keepAspectRatio) {
          const maxHeight = (this.height >= this.width ? size.y : size.y * (1 / ratio)) - 30;
          this.height = origHeight + (curY - currentEventPosition.pageY) * 2 * yMod;
          this.height = Math.max(30, this.height);
          this.height = Math.min(maxHeight, this.height);
          this.width = this.height * ratio;
        } else {
          this.width = origWidth + (curX - currentEventPosition.pageX) * 2 * xMod;
          this.height = origHeight + (curY - currentEventPosition.pageY) * 2 * yMod;
          this.limitWidthAndHeightToMapSize();
        }
        this.render();
      };

      const onHandleRelease = () => {
        this.map.dragging.enable();
        Leaflet.DomEvent.removeListener(<any>this.map, 'mousemove', onHandleMove);
        Leaflet.DomEvent.removeListener(this.map.getContainer(), 'touchmove', onHandleMove);
        Leaflet.DomEvent.removeListener(<any>this.map, 'mouseup touchend', onHandleRelease);
        Leaflet.DomEvent.removeListener(this.map.getContainer(), 'touchend', onHandleRelease);
        Leaflet.DomEvent.addListener(handle, 'mousedown touchstart', onHandleSelect);
        this.map.fire('areaSelectChange');
      };

      Leaflet.DomEvent.addListener(<any>this.map, 'mousemove', onHandleMove);
      Leaflet.DomEvent.addListener(this.map.getContainer(), 'touchmove', onHandleMove);
      Leaflet.DomEvent.addListener(<any>this.map, 'mouseup', onHandleRelease);
      Leaflet.DomEvent.addListener(this.map.getContainer(), 'touchend', onHandleRelease);
    };

    Leaflet.DomEvent.addListener(handle, 'mousedown touchstart', onHandleSelect);
  }

  /**
   * Limits the _width and _height so that they fit inside the map and they
   * are not too small. The 'this' context must be provided as the argument.
   */
  private limitWidthAndHeightToMapSize(): void {
    // Don't let the area all the way to the edges of the map.
    const size = this.map.getSize();
    this.width = Math.max(30, this.width); // 30 is some tolerance in pixels
    this.height = Math.max(30, this.height);
    this.width = Math.min(size.x - 30, this.width);
    this.height = Math.min(size.y - 30, this.height);
  }

  private render(): void {
    const size = this.map.getSize();
    const handleOffset = Math.round(this.areaHandle.nw.offsetWidth / 2);
    const topBottomHeight = Math.round((size.y - this.height) / 2);
    const leftRightWidth = Math.round((size.x - this.width) / 2);

    const setDimensions = (element: HTMLElement, size: AreaSize) => {
      element.style.width = `${size.width}px`;
      element.style.height = `${size.height}px`;
      element.style.top = `${size.top}px`;
      element.style.left = `${size.left}px`;
      element.style.bottom = `${size.bottom}px`;
      element.style.right = `${size.right}px`;
    };

    setDimensions(this.areaShade, {
      width: this.width,
      height: this.height,
      top: topBottomHeight,
      left: leftRightWidth,
    });

    setDimensions(this.areaHandle.nw, { left: leftRightWidth - handleOffset, top: topBottomHeight - 7 });
    setDimensions(this.areaHandle.ne, { right: leftRightWidth - handleOffset, top: topBottomHeight - 7 });
    setDimensions(this.areaHandle.sw, { left: leftRightWidth - handleOffset, bottom: topBottomHeight - 7 });
    setDimensions(this.areaHandle.se, { right: leftRightWidth - handleOffset, bottom: topBottomHeight - 7 });
  }

  private onMapResize(): void {
    this.limitWidthAndHeightToMapSize();
    this.render();
  }

  toggleAreaHandleWarning(shouldAdd: boolean): void {
    if (shouldAdd) {
      this.areaHandle.nw.classList.add(this.areaHandleWarnClass);
      this.areaHandle.ne.classList.add(this.areaHandleWarnClass);
      this.areaHandle.sw.classList.add(this.areaHandleWarnClass);
      this.areaHandle.se.classList.add(this.areaHandleWarnClass);
    } else {
      this.areaHandle.nw.classList.remove(this.areaHandleWarnClass);
      this.areaHandle.ne.classList.remove(this.areaHandleWarnClass);
      this.areaHandle.sw.classList.remove(this.areaHandleWarnClass);
      this.areaHandle.se.classList.remove(this.areaHandleWarnClass);
    }
  }

  private eventPosition(event: MouseEvent | TouchEvent): { pageX: number; pageY: number } {
    return event instanceof MouseEvent ? event : event.touches.item(0);
  }
}
