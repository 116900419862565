// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: bugra@sketchup.com (Bugra Barin)
//
// TODO (Cole | Matt, CWS-1028): Expose the translate method on all Vue components such that the $t
// method may be used in templates. This'll most likely require re-writing this file to behave
// similarly to the i18n module in sketchup_profile code base.

import Cookies from 'js-cookie';
import { getQueryParamValue } from './Utils';

let currentLanguage_: string;
let translatedText_: {
  [key: string]: string;
};

export const langToLocale = {
  cs: 'cs-CZ',
  de: 'de-DE',
  en: 'en-US',
  es: 'en-ES',
  fr: 'fr-FR',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  pl: 'pl-PL',
  'pt-br': 'pt-BR',
  ru: 'ru-RU',
  sv: 'sv-SE',
  'zh-cn': 'zh-CN',
  'zh-tw': 'zh-TW',
};

export interface Replacements {
  [key: string]: string;
}

/*
 * Provides ability to translate strings found outside of a Vue instance.
 * @param key The string to be translated
 * @param replacements Strings to be inserted into a translated string.
 */
export function $t(key: string, replacements?: Replacements): string {
  const translation = translate(key);
  return replace(translation, replacements);
}

export async function initializeL10n(): Promise<void> {
  const lang = getCurrentLanguage().toLowerCase();
  if (lang !== 'en') {
    try {
      const response = await fetch(`/static/js/l10n/${lang}.json`);
      if (response.status === 200) {
        translatedText_ = await response.json();
      }
    } catch (e) {
      console.error(e);
      currentLanguage_ = 'en';
    }
  }
}

/**
 * Used by Vue to make functions available to the Vue instance as a plugin.
 *
 * @param Vue The Vue instance.
 * @param translations The translations to use for comparison.
 */
// eslint-disable-next-line
export function install(vue: any): void {
  vue.prototype.$t = (key: string, replacements?: Replacements) => {
    const translation = translate(key);
    return replace(translation, replacements);
  };
}

/**
 * Gets the current language code, as passed on the query string as hl= or
 * as stored in an 'hl' cookie.
 * Returns the current language code, or 'en' if none is set. The returned
 * string is always lowercase.
 */
export function getCurrentLanguage(): string {
  currentLanguage_ = (currentLanguage_ || getQueryParamValue('hl') || Cookies.get('hl') || 'en').toLowerCase();
  if (currentLanguage_ === 'en-us') {
    currentLanguage_ = 'en';
  }
  return currentLanguage_;
}

/**
 * Performs replacements on string.  This provides the ability to do
 * the following:
 * {{ $t('You have used {count} out of {limit} licenses.', {count: '5', limit: '10'}) }}
 * which, when count = 5 and limit = 10, will be rendered into:
 * You have used 5 out of 10.
 *
 * @param translation The translation we'll inject replacements into.
 * @param replacements The strings that will be inserted.
 */
function replace(translation: string, replacements?: Replacements): string {
  return translation.replace(/\{\w+\}/g, placeholder => {
    const key = placeholder.replace('{', '').replace('}', '');

    if (replacements != null && replacements[key] !== undefined) {
      return replacements[key];
    }

    return placeholder;
  });
}

/**
 * Returns a localized version of a given string. If we're asked to translate
 * a string that we don't have in our translation memory, we simply return the
 * English string.
 */
function translate(text: string): string {
  if (!translatedText_) {
    // English takes this shortcut.
    return text;
  }

  const translatedString = translatedText_[text];
  if (!translatedString) {
    return text;
  }

  return translatedString;
}
