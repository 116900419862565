export enum TrackingEventEnum {
  LaunchedApplication = 'Launched Application',
  ClosedApplication = 'Closed Application',
  ChangedAddress = 'Changed Map Address',
  ChangedMapPosition = 'Changed Map Position',
  ChangedMapType = 'Changed Map Type',
  ClickedZoomIn = 'Clicked Zoom In',
  ClickedZoomOut = 'Clicked Zoom Out',
  SelectedProvider = 'Selected Map Provider',
  ChangedImportLevel = 'Changed Import Level',
  ClickedSelectRegion = 'Clicked Select Region',
  CanceledSelectRegion = 'Canceled Select Region',
  ClickedTileImport = 'Clicked Map Tile Import',
  ClickedTakeMeToPreDesign = 'Clicked Take me to PreDesign',
  ToggledTileBoundaries = 'Toggled Tile Boundaries',
  ClickedTakeTheSurvey = 'Clicked Take The Survey',
  ClickedShowHelp = 'Clicked Show Help',
  ClickedPlansAndPricing = 'Clicked Plans And Pricing',
  TriggeredEOSBlockedAccess = 'Triggered EOS Blocked Access',
  ClickedEOSBlockedAccessReadMore = 'Clicked EOS Blocked Access - Read More',
  ClickedEOSBlockedAccessUpdateSketchUp = 'Clicked EOS Blocked Access - Update SketchUp',
  ClickedEOSWarningDismiss = 'Clicked EOS Warning - Dismiss',
  ClickedEOSWarningReadMore = 'Clicked EOS Warning - Read More',
  ClickedEOSWarningUpdateSketchUp = 'Clicked EOS Warning - Update SketchUp',
  TriggeredEOSWarning = 'Triggered EOS Warning',
}
