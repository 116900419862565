import { Platform } from '../enums/platform.enum';
import { ServicesInterface } from '../interfaces/ServicesInterface';
import * as I10n from '../l10n';
import { Logger } from '../Logger';
import { LoginResponse } from '../Login';
import { getClientMajorVersion, getDeviceId } from '../Utils';

export interface GainsightIdentity {
  id: string;
  role: string;
  sku: string;
  gainsightCustomUserLocale: string;
  gainsightCustomUserLangISO6391: string;
  gainsightCustomSketchupLanguage: string;
  licenseNumber: number;
  platform: Platform;
  clientMajorVersion?: number;
  deviceId?: string;
}

export const generateGainsightIdentity = (
  sku: string,
  loginResponse: LoginResponse,
  services: ServicesInterface,
  platform: Platform,
  exceptionLogger: Logger,
): GainsightIdentity => {
  const language = I10n.getCurrentLanguage();
  const locale = I10n.langToLocale[language.toLowerCase()];
  if (!locale) {
    exceptionLogger.logExceptionMessage(
      `Could not find locale for language ${language}, defaulting to English for analytics`,
    );
  }
  const iso6391 = locale ? locale.split('-')[0] : 'en';

  const deviceId = getDeviceId(services, platform);
  const clientMajorVersion = services.client_version ? getClientMajorVersion(services.client_version) : undefined;

  let identity = <GainsightIdentity>{
    id: loginResponse.trimbleid,
    sku,
    gainsightCustomUserLocale: locale,
    gainsightCustomUserLangISO6391: iso6391,
    gainsightCustomSketchupLanguage: language,
    licenseNumber: services.license,
    platform,
  };
  if (deviceId) {
    identity = { ...identity, deviceId };
  }

  if (clientMajorVersion) {
    identity = { ...identity, clientMajorVersion };
  }
  return identity;
};
