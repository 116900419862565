
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

import { EosNotificationService } from '../typescript/eos-notifications/services/eos-notification.service';
import { Token } from '../typescript/inject-tokens';
import { MapServices } from '../typescript/interfaces/MapInterfaces';
import { getCurrentLanguage } from '../typescript/l10n';
import { TrackingEventEnum } from '../typescript/tracking/tracking-event.enum';
import { TrackingService } from '../typescript/tracking/tracking.service';

@Component({})
export default class EosNotificationModal extends Vue {
  @Prop() services: MapServices;

  @Inject(Token.TrackingService) trackingService!: TrackingService;

  date: string | undefined = undefined;
  dismissed: boolean = false;

  async created() {
    const notification = await EosNotificationService.getEosNotification();
    this.date = notification?.blockedAfter;
    if (this.showEosModal) {
      this.trackingService.send(TrackingEventEnum.TriggeredEOSWarning, {
        sketchup_version: this.services.client_version,
      });
    }
  }

  // make component react on date and dismissed value changes
  data() {
    return {
      date: this.date,
      dismissed: this.dismissed,
    };
  }

  get showEosModal(): boolean {
    return this.dismissed !== true && this.date !== undefined;
  }

  get localDate(): string {
    return new Date(this.date).toLocaleDateString(getCurrentLanguage(), {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  clickedUpgradeSketchup = () => this.trackingService.send(TrackingEventEnum.ClickedEOSWarningUpdateSketchUp);

  clickedReadMore = () => this.trackingService.send(TrackingEventEnum.ClickedEOSWarningReadMore);

  closePopup(): void {
    this.dismissed = true;
    this.trackingService.send(TrackingEventEnum.ClickedEOSWarningDismiss);
  }
}
