// Copyright 2016 Trimble Inc. All Rights Reserved.
// Author: bugra@sketchup.com (Bugra Barin)

export enum GeoCodeResultType {
  COUNTRY = 0,
  STATE = 1,
  COUNTY = 2,
  CITY = 3,
  ZIP = 4,
  SPLC = 5,
  STREET = 6,
  ROUTENUMBER = 7,
  ROUTEALPHA = 8,
  POI = 9,
  POISTREET = 10,
  FULLPOSTCODE = 11,
  POITYPE = 12,
  CROSSSTREET = 13,
  LATLON = 14,
  CUSTOMPLACE = 15,
  NONE = 16,
}

export interface GeocoderResponseLatLng {
  Latitude: number;
  Longitude: number;
}

export interface GeocoderResponseMapView {
  Lat: number;
  Lon: number;
}

export interface GeocoderResponse {
  Label: string;
  MapView: GeocoderResponseMapView;
  ResultType: GeoCodeResultType;
}

export interface ReverseGeocoderResponse {
  City: string;
  Country: string;
}
