import { AddlocationFeatureIds } from './entitlements/addlocation-feature-ids.enum';
import { ImportResolutionType } from './enums/import-resolution-type.enum';
import { Platform } from './enums/platform.enum';

export interface AppFeatures {
  mapTypeSelection: boolean;
  satelliteImport: boolean;
  resolutionType: ImportResolutionType;
  tileBoundaries: boolean;
  satelliteProviderSelection: boolean;
  satelliteBing: boolean;
  upselling: boolean;
  promotions: boolean;
  surveys: boolean;
  requiresLogin: boolean;
}

export function getAppFeatures(platform: Platform, activeFeatures: Set<string>): AppFeatures {
  const atLeastGold =
    activeFeatures.has(AddlocationFeatureIds.Gold) || activeFeatures.has(AddlocationFeatureIds.Platinum);

  switch (platform) {
    case Platform.Schools:
      return {
        mapTypeSelection: false,
        promotions: false,
        resolutionType: ImportResolutionType.MapZoom,
        satelliteBing: false,
        satelliteImport: false,
        satelliteProviderSelection: false,
        surveys: false,
        tileBoundaries: false,
        upselling: false,
        requiresLogin: false,
      };

    case Platform.P11:
      return {
        mapTypeSelection: true,
        promotions: true,
        resolutionType: atLeastGold ? ImportResolutionType.UserSpecified : ImportResolutionType.MapZoom,
        satelliteBing: atLeastGold,
        satelliteImport: atLeastGold,
        satelliteProviderSelection: atLeastGold,
        tileBoundaries: atLeastGold,
        surveys: true,
        upselling: true,
        requiresLogin: true,
      };

    case Platform.iPad:
      return {
        mapTypeSelection: true,
        promotions: false,
        resolutionType: ImportResolutionType.MapZoom,
        satelliteBing: false,
        satelliteImport: true,
        satelliteProviderSelection: false,
        tileBoundaries: false,
        surveys: false,
        upselling: false,
        requiresLogin: true,
      };

    case Platform.Desktop:
      return {
        mapTypeSelection: true,
        promotions: true,
        resolutionType: atLeastGold ? ImportResolutionType.UserSpecified : ImportResolutionType.MapZoom,
        satelliteBing: atLeastGold,
        satelliteImport: true,
        satelliteProviderSelection: true,
        tileBoundaries: atLeastGold,
        surveys: true,
        upselling: true,
        requiresLogin: true,
      };
  }
}
