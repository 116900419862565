
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ImportLevelEnum } from '../typescript/enums/import-level.enum';
import EventBus from '../typescript/EventBus';
import * as I10n from '../typescript/l10n';

import Tooltip from './tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class ResolutionSelect extends Vue {
  @Prop({ required: true }) importLevel: number;

  showTooltip: boolean = false;

  readonly importLevelEnum = ImportLevelEnum;

  readonly importLevels = Object.freeze([
    ImportLevelEnum.VeryLow,
    ImportLevelEnum.Low,
    ImportLevelEnum.Medium,
    ImportLevelEnum.High,
    ImportLevelEnum.VeryHigh,
  ]);

  getTickmarkWidth(): { width: string } {
    // Use this to set the width on the tickmarks so they line up correctly.
    const widthPercentage = 100 / (this.importLevels.length - 1);
    return { width: `${widthPercentage}%` };
  }

  onImportZoomLevelChange(event: Event): void {
    const currentLayerIndex = parseInt((<HTMLInputElement>event.target).value);
    EventBus.$emit('update:importLevel', currentLayerIndex);
  }

  importResolutionName(level: number): string {
    switch (level) {
      case ImportLevelEnum.VeryLow:
        return I10n.$t('Very Low');
      case ImportLevelEnum.Low:
        return I10n.$t('Low');
      case ImportLevelEnum.Medium:
        return I10n.$t('Medium');
      case ImportLevelEnum.High:
        return I10n.$t('High');
    }
    return '';
  }
}
