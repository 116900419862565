
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ProviderDisabledReasonEnum } from '../typescript/enums/provider-disabled-reason.enum';

@Component({
  components: {},
})
export default class ProviderDisabledReason extends Vue {
  @Prop({ required: true })
  reason: ProviderDisabledReasonEnum;

  reasons = ProviderDisabledReasonEnum;
}
