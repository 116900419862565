
import Vue from 'vue';
import { Component, Prop, Inject } from 'vue-property-decorator';

import { Platform } from '../typescript/enums/platform.enum';
import { Token } from '../typescript/inject-tokens';
import { getCurrentLanguage } from '../typescript/l10n';
import { TrackingEventEnum } from '../typescript/tracking/tracking-event.enum';
import { TrackingService } from '../typescript/tracking/tracking.service';

@Component({})
export default class UpsellInfo extends Vue {
  @Prop({ required: true }) upsell: 'Bing' | 'Satellite';

  // This is a safeguard to make sure that we never display upsell on iPad as
  // it violates T&C with Apple, which could cause sketchup be removed from
  // the AppStore and nobody wants to be responsible for that.
  @Prop({ required: true }) platform: Platform;

  @Inject(Token.TrackingService) trackingService!: TrackingService;

  readonly Platform = Platform;

  openTrackedLink(): void {
    window.open(`https://sketchup.com/${getCurrentLanguage()}/plans-and-pricing`, '_blank', 'noopener');
    this.trackingService.send(TrackingEventEnum.ClickedPlansAndPricing, { upsell: this.upsell });
  }
}
