import { TrackingEventEnum } from './tracking-event.enum';
import { TrackingServiceInterface } from './tracking-service.interface';

export class TrackingService implements TrackingServiceInterface {
  private readonly queue = new Set<{
    event: TrackingEventEnum;
    properties: object | undefined;
  }>();

  constructor(private readonly services: Array<TrackingServiceInterface> = []) {
    this.flush();
  }

  addTrackingService(...services: Array<TrackingServiceInterface>) {
    this.services.push(...services);
    this.flush();
  }

  send(event: TrackingEventEnum, properties: object | undefined = {}): void {
    console.debug(`Passing ${event} to ${this.services.length} services`);
    if (this.services.length) {
      this.services.forEach((service: TrackingServiceInterface) => service.send(event, properties));
    } else {
      // queue messages
      this.queue.add({ event, properties });
    }
  }

  async sendAsync(event: TrackingEventEnum, properties: object | undefined = {}): Promise<void> {
    console.debug(`Passing ${event} to ${this.services.length} services`);
    await Promise.all(this.services.map((service: TrackingServiceInterface) => service.sendAsync(event, properties)));
  }

  private flush(): void {
    if (this.queue.size) {
      this.queue.forEach(({ event, properties }) => this.send(event, properties));
      this.queue.clear();
    }
  }
}
