
// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: cole@sketchup.com (Cole Carroll)

import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { $t } from '../typescript/l10n';

/**
 * A modal dialog component used to notify the user they must be logged in.
 */
@Component({})
export default class LoggedOutModal extends Vue {
  get loggedOutMessage(): string {
    const reMatches: Array<string> = navigator.userAgent.match(/SketchUp( (Pro|Make))?\/(\d+)\.\d+/i) ?? [];
    const skpVersion = reMatches.length === 4 ? Number(reMatches[3] ?? 0) : 0;
    return $t(`Look for it in the ${skpVersion > 18 ? 'upper right' : 'lower left'} corner of the modeling window.`);
  }
}
