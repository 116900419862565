import * as Leaflet from 'leaflet';

import { AppFeatures } from '../application-features';
import { MapService } from '../interfaces/MapInterfaces';
import { ServicesInterface } from '../interfaces/ServicesInterface';
import * as I10n from '../l10n';
import Cookies from 'js-cookie';

import { MapTileProviderEnum } from './enums/map-tile-provider.enum';
import { MapTypeEnum } from './enums/map-type.enum';
import { SatelliteTileProviderEnum } from './enums/satellite-tile-provider.enum';
import { MapConfig } from './interfaces/map-config.interface';
import { MapDecorations } from './map-decorations';

export class MapModel {
  static MAX_TILE_IMPORT_QUANTITY = 1250;

  map: Leaflet.Map;
  decorations: any;
  activeTileProvider: MapTileProviderEnum = undefined;
  activeMapType: MapTypeEnum = undefined;
  activeSatelliteType: SatelliteTileProviderEnum = undefined;

  private currentBaseLayer: Leaflet.Layer;
  private currentLayerProvider: MapTileProviderEnum;
  private defaultZoomLevel: number = 17;

  constructor(
    private readonly services: ServicesInterface,
    private readonly config: MapConfig,
    private readonly appFeatures: AppFeatures,
  ) {
    if (this.services.map_services.length < 1) {
      throw new Error('Unexpected layer count');
    }

    this.map = this.createMap();
    this.initBaseLayer();

    // Create the decorations plugin.
    this.decorations = new MapDecorations(this.map);
  }

  activateBaseLayer(activeTileProvider: MapTileProviderEnum): void {
    const lastLayerProvider = this.currentLayerProvider;
    this.currentLayerProvider = MapModel.layerProviderForMapProvider(activeTileProvider);

    if (this.currentLayerProvider !== lastLayerProvider) {
      this.activeTileProvider = activeTileProvider;

      if (this.currentBaseLayer) {
        this.map.removeLayer(this.currentBaseLayer);
      }

      const [newLayer, service] = this.createLayerForProvider(this.currentLayerProvider);
      this.currentBaseLayer = newLayer;
      this.map.addLayer(newLayer);

      this.map.options.minZoom = service.start_zoom;
      this.map.options.maxZoom = service.end_zoom;
      const currentZoom = this.map.getZoom();
      if (currentZoom < service.start_zoom) {
        this.map.setZoom(service.start_zoom);
      } else if (currentZoom > service.end_zoom) {
        this.map.setZoom(service.end_zoom);
      }
    }
  }

  mapTypeToProvider(mapType: MapTypeEnum): MapTileProviderEnum {
    switch (mapType) {
      case MapTypeEnum.streetMap:
        return MapTileProviderEnum.streetMap;
      case MapTypeEnum.satellite:
        if (this.activeSatelliteType) {
          return MapModel.satelliteToMapTileProvider(this.activeSatelliteType);
        } else {
          return MapTileProviderEnum.satelliteBing;
        }
    }
  }

  setMapView(coordinates: Leaflet.LatLngExpression, zoomLevel?: number): void {
    this.map.setView(coordinates, zoomLevel || this.defaultZoomLevel);
  }

  get activeTileProviderService(): MapService {
    return this.findMapServiceByProvider(this.activeTileProvider);
  }

  private get zoomBoundaries(): { minZoom: number; maxZoom: number } {
    let minZoom = 100;
    let maxZoom = 0;
    this.services.map_services.forEach((service: MapService) => {
      minZoom = Math.min(minZoom, service.start_zoom);
      maxZoom = Math.max(maxZoom, service.end_zoom);
    });
    return { minZoom, maxZoom };
  }

  private createMap(): Leaflet.Map {
    // Assuming bounds are the same for all services, so use the first one.
    const bounds = MapModel.serviceBounds(this.services.map_services[0]);

    const config = {
      attributionControl: false,
      center: bounds.getCenter(),
      zoom: 3,
      worldCopyJump: true,
      ...this.zoomBoundaries,
    };

    this.map = Leaflet.map('map_canvas', config);

    // Create an attribution control without the Leaflet link.
    const attribution = Leaflet.control.attribution({ prefix: '' });
    if (this.services['stack'].toLowerCase() !== 'prod') {
      // Add the stack to the attribution just to ease testing.
      attribution.setPrefix('Stack: ' + this.services['stack']);
    }
    attribution.addTo(this.map);

    // Disable default leaflet zoom in and zoom out buttons.
    this.map.zoomControl.remove();

    Leaflet.control.scale().addTo(this.map);

    return this.map;
  }

  /**
   * Finds what service is active or preferred by the user and activates it.
   */
  private initBaseLayer(): void {
    // Activate whichever layer is supposed to be active.
    this.activeTileProvider = MapModel.getDefaultActiveTileProvider(
      this.services.map_services,
      this.appFeatures,
      Cookies.get(this.config.lastServiceProviderCookieName),
    );

    switch (this.activeTileProvider) {
      case MapTileProviderEnum.satelliteBing:
        this.activeMapType = MapTypeEnum.satellite;
        this.activeSatelliteType = SatelliteTileProviderEnum.bing;
        break;
      case MapTileProviderEnum.satelliteDigitalGlobe:
        this.activeMapType = MapTypeEnum.satellite;
        this.activeSatelliteType = SatelliteTileProviderEnum.digitalGlobe;
        break;
      default:
        this.activeMapType = MapTypeEnum.streetMap;
        const bestAvailableSatelliteProvider = MapModel.findBestServiceProvider(
          [MapTileProviderEnum.satelliteBing, MapTileProviderEnum.satelliteDigitalGlobe],
          this.services.map_services,
          this.appFeatures,
        );
        this.activeSatelliteType = bestAvailableSatelliteProvider
          ? MapModel.mapTileProviderToSatellite(bestAvailableSatelliteProvider)
          : undefined;
    }

    this.activateBaseLayer(this.activeTileProvider);
  }

  private createLayerForProvider(mapProvider: MapTileProviderEnum): [Leaflet.Layer, MapService] {
    switch (mapProvider) {
      case MapTileProviderEnum.satelliteDigitalGlobe: {
        const currentMapService = this.findMapServiceByProvider(mapProvider);
        const overlayService = this.findMapServiceByProvider(MapTileProviderEnum.satelliteDigitalGlobeOverlay);
        const digitalGlobeLayer = MapModel.createMapLayer(currentMapService);
        const layers = [digitalGlobeLayer];
        if (overlayService) {
          layers.push(MapModel.createMapLayer(overlayService));
        }
        return [new Leaflet.LayerGroup(layers), currentMapService];
      }

      default: {
        const currentMapService = this.findMapServiceByProvider(mapProvider);
        return [MapModel.createMapLayer(currentMapService), currentMapService];
      }
    }
  }

  get availableSatelliteTypes(): Set<SatelliteTileProviderEnum> {
    const availableSatelliteTypes = new Set<SatelliteTileProviderEnum>();

    const addIfAvailable = (mapTileType: MapTileProviderEnum) => {
      if (this.findMapServiceByProvider(mapTileType)) {
        availableSatelliteTypes.add(MapModel.mapTileProviderToSatellite(mapTileType));
      }
    };

    addIfAvailable(MapTileProviderEnum.satelliteDigitalGlobe);
    addIfAvailable(MapTileProviderEnum.satelliteBing);

    return availableSatelliteTypes;
  }

  findMapServiceByProvider(provider: MapTileProviderEnum): MapService | undefined {
    return this.services.map_services.find(service => service.map_tile_provider === provider);
  }

  findMapServiceIndexByProvider(provider: MapTileProviderEnum): number {
    return this.services.map_services.findIndex(service => service.map_tile_provider === provider);
  }

  static findBestServiceProvider(
    prioritisedProviders: Array<MapTileProviderEnum>,
    mapServices: Array<MapService>,
    appFeatures: AppFeatures,
  ): MapTileProviderEnum {
    const allowedProviders = prioritisedProviders.filter(mapTileProvider => {
      switch (mapTileProvider) {
        case MapTileProviderEnum.satelliteBing:
          return appFeatures.satelliteBing;
        case MapTileProviderEnum.satelliteDigitalGlobe:
          return appFeatures.satelliteImport;
      }
      return true;
    });

    return MapModel.findBestServiceProvider_(mapServices, allowedProviders);
  }

  static getDefaultActiveTileProvider(
    mapServices: Array<MapService>,
    appFeatures: AppFeatures,
    providerPreference: string | null,
  ): MapTileProviderEnum {
    const filteredProviders = mapServices
      .map(mapService => mapService.map_tile_provider.toString())
      .filter(mapTileProvider => {
        switch (mapTileProvider) {
          case MapTileProviderEnum.satelliteDigitalGlobe:
            return appFeatures.satelliteImport;

          case MapTileProviderEnum.satelliteBing:
            return appFeatures.satelliteBing;
        }
        return true;
      });
    const validProviders = new Set(filteredProviders);

    if (validProviders.has(providerPreference)) {
      return <MapTileProviderEnum>providerPreference;
    } else {
      return MapModel.findBestServiceProvider(
        [MapTileProviderEnum.satelliteBing, MapTileProviderEnum.satelliteDigitalGlobe, MapTileProviderEnum.streetMap],
        mapServices,
        appFeatures,
      );
    }
  }

  static satelliteToMapTileProvider(satelliteProvider: SatelliteTileProviderEnum): MapTileProviderEnum {
    switch (satelliteProvider) {
      case SatelliteTileProviderEnum.bing:
        return MapTileProviderEnum.satelliteBing;
      case SatelliteTileProviderEnum.digitalGlobe:
        return MapTileProviderEnum.satelliteDigitalGlobe;
      default:
        throw new Error(`Unexpected satellite provider ${satelliteProvider}`);
    }
  }

  static mapTileProviderToSatellite(mapTileProvider: MapTileProviderEnum): SatelliteTileProviderEnum {
    switch (mapTileProvider) {
      case MapTileProviderEnum.satelliteBing:
        return SatelliteTileProviderEnum.bing;
      case MapTileProviderEnum.satelliteDigitalGlobe:
        return SatelliteTileProviderEnum.digitalGlobe;
      default:
        throw new Error(`Unexpected map tile provider ${mapTileProvider}`);
    }
  }

  private static layerProviderForMapProvider(mapProvider: MapTileProviderEnum): MapTileProviderEnum {
    switch (mapProvider) {
      case MapTileProviderEnum.streetMap:
      case MapTileProviderEnum.satelliteBing:
      case MapTileProviderEnum.satelliteDigitalGlobe:
        return mapProvider;
      default:
        throw new Error(`Cannot create map layer for provider ${mapProvider}`);
    }
  }

  private static findBestServiceProvider_(
    services: Array<MapService>,
    prioritisedProviders: Array<MapTileProviderEnum>,
  ): MapTileProviderEnum {
    const servicesByProvider = new Map(services.map(service => [service.map_tile_provider, service]));
    return prioritisedProviders.find(provider => servicesByProvider.has(provider));
  }

  private static createMapLayer(service: MapService): Leaflet.TileLayer {
    const copyrightText = I10n.$t('Content usage copyright');
    const layerConfig = {
      minZoom: service.start_zoom,
      maxZoom: service.end_zoom,
      tileSize: service.image_size || 256,
      zoomOffset: service.zoom_offset || 0,
      attribution: `<a href="https://maps.trimble.com/copyrights/" target="_blank" rel="noopener" />${copyrightText}</a>`,
    };
    const template = service.view_url_template ? service.view_url_template : service.tile_url_template;
    const leafletUrl = template.replace(/\$\{/g, '{');
    return Leaflet.tileLayer(leafletUrl, layerConfig);
  }

  private static serviceBounds(service: MapService): Leaflet.LatLngBounds {
    const southWest = MapModel.unprojectSM(service.south, service.west);
    const northEast = MapModel.unprojectSM(service.north, service.east);
    return Leaflet.latLngBounds(southWest, northEast);
  }

  /**
   * Converts from spherical mercator coordinates to Lat-Long.
   */
  private static unprojectSM(lat: number, lng: number): Leaflet.LatLng {
    const R = 6378137;
    const d = 180 / Math.PI;
    const glat = (2 * Math.atan(Math.exp(lat / R)) - Math.PI / 2) * d;
    const glng = (lng * d) / R;
    return Leaflet.latLng(glat, glng);
  }
}
