// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: brandon@sketchup.com (Brandon Feltman)
// Author: cole@sketchup.com (Cole Carroll)
import * as Sentry from '@sentry/browser';

/**
 * This class is used to initialize and send exceptions to Sentry.
 */
export class Logger {
  static init(stack: string): Logger {
    return new Logger(stack);
  }

  constructor(readonly stack: string) {
    Sentry.init({
      dsn: 'https://c2a5ef24e5f447c4a74098553a7a05ce@o24846.ingest.sentry.io/6134370',
      environment: this.stack,
    });
  }

  /**
   * Used to manually capture error messages and send them to Sentry.
   * Todo (brandon): CWS-1542 Add env variable to control flow.
   */
  logExceptionMessage(errorMsg: string): void {
    if (!this.stack.includes('localdev')) {
      Sentry.captureMessage(errorMsg);
    } else {
      console.error(errorMsg);
    }
  }

  /**
   * Used to manually capture exceptions and send them to Sentry.
   */
  logException(error: Error): void {
    if (!this.stack.includes('localdev')) {
      Sentry.captureException(error);
    } else {
      console.error(error.message);
    }
  }

  /**
   * Used to capture and handle unhandled rejections appropriately.
   *
   * @param unhandledRejection unhandled rejections that bubble to the console.
   */
  logUnhandledRejection(unhandledRejection: string | PromiseRejectionEvent): void {
    if (typeof unhandledRejection === 'string') {
      this.logExceptionMessage(
        'Uncaught promise rejection' +
          (unhandledRejection && unhandledRejection.trim().length > 0 ? unhandledRejection : ''),
      );
    } else {
      this.logException(unhandledRejection.reason);
    }
  }
}
