
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Platform } from '../typescript/enums/platform.enum';
import EventBus from '../typescript/EventBus';
import * as I10n from '../typescript/l10n';
import { SatelliteTileProviderEnum } from '../typescript/map/enums/satellite-tile-provider.enum';

import ProviderDisabledReason from './provider-disabled-reason.vue';
import Tooltip from './tooltip.vue';
import UpsellInfo from './upsell.vue';
@Component({
  components: {
    UpsellInfo,
    Tooltip,
    ProviderDisabledReason,
  },
})
export default class ProviderSelect extends Vue {
  @Prop({ required: true }) selectedProvider: SatelliteTileProviderEnum;
  @Prop({ required: true }) providerDisabledReasons: Map<SatelliteTileProviderEnum, ProviderDisabledReason>;

  readonly satelliteProviders = SatelliteTileProviderEnum;
  readonly platforms = Platform;

  showTooltip: boolean = false;
  showDigitalGlobeTooltip: boolean = false;
  showBingTooltip: boolean = false;

  isProviderSelected(providerName: SatelliteTileProviderEnum): boolean {
    return this.selectedProvider === providerName;
  }

  onProviderChange(provider: SatelliteTileProviderEnum): void {
    // Decouple display value from the official value we emit to parent.
    if (provider !== this.selectedProvider) {
      EventBus.$emit('update:satelliteProvider', provider);
    }
  }
}
